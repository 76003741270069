@charset "utf-8";

/*@import url('datepicker.css');*/
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

body {
    margin: 0;
    padding: 0;
    font-family: 'Mulish', sans-serif !important;
    font-size: 13px;
    color: #3a3a3a;
    font-weight: 400;
    background-color: #fff;
    min-height: 100vh;
}

* {
    margin: 0px;
    padding: 0px;
} 

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.clr,
.clear {
    clear: both;
}

header,
footer,
aside,
article,
section,
details,
nav,
figure {
    position: relative; 
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
li,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

img {
    border: 0;
    outline: none;
    max-width: 100%; 
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
    max-width: 100%;
    outline: 0; 
}

input[type=date].form-control{ 
    text-transform: uppercase !important;
    font-size: 14px; 
    font-weight: 500; 
    color: #000;
    padding: 10px 15px !important;
}

table {  width: 100%; } 
p { margin: 0; padding: 0; }
.fltL { float: left; }
.fltR { float: right; }
.padding_none { padding: 0 !important;}
.border_none{border:none !important}
.navbar-toggle {  background-color: #fff !important; }
.icon-bar { background-color: #000 !important; }
.p_right0{ padding-right:0px !important }
.p_left0{ padding-left:0px !important }
.padding0{ padding: 0 !important }
.Margin0{ margin: 0 !important }
 
.Disable{ background: #bfbebe !important; }
::-webkit-scrollbar { width: 10px; height: 10px; }
::-webkit-scrollbar-track { background: #000; }
::-webkit-scrollbar-thumb { background: #367dd6; } 

.Icon.Cross{ cursor: pointer; }
.Icon.Cross:after{content: ''; position: absolute; top: -4px; width: 2px; height: 30px; background: #939299; transform: rotate(45deg); left: 10px;}
.Icon.Cross.active:after{ content: none;}

.CheckBox{display:block;position:relative;padding:0 30px 0 0;cursor:pointer;font-size:17px;-webkit-user-select:none;user-select:none;margin:0;color:#000;font-weight:600;min-height:26px}
.CheckBox+.CheckBox{margin:25px 0 0 0}
.CheckBox input{position:absolute;opacity:0;cursor:pointer;height:0;width:0}
.CheckBox .Checkmark{position:absolute;top:0;right:0;height:23px;width:26px;background-color:#fff;border:1px solid #ddd;border-radius:5px}
.CheckBox input:checked~.Checkmark{background-color:#367dd6;border-color:#367dd6}
.CheckBox .Checkmark:after{content:"";position:absolute;display:none}
.CheckBox input:checked~.Checkmark:after{display:block}
.CheckBox .Checkmark:after{left:9px;top:4px;width:6px;height:11px;border:solid #fff;border-width:0 2px 2px 0;transform:rotate(55deg)}

.Radio{display:inline-block;position:relative;padding-left:30px;margin:0 0 0 0;cursor:pointer;font-size:16px;-webkit-user-select:none;user-select:none;color:#000;font-weight:600}
.Radio input{position:absolute;opacity:0;cursor:pointer;left:0;top:0;width:20px;height:20px;z-index:9}
.Radio .Radiomark{position:absolute;top:0;left:0;height:20px;width:20px;background-color:#fff;border-radius:50%;border:1px solid #ddd}
.Radio:hover input~.Radiomark{background-color:#ccc}
.Radio input:checked~.Radiomark{background-color:#5bb9ba;border-color:#5ab7b8}
.Radio .Radiomark:after{content:"";position:absolute;display:none}
.Radio input:checked~.Radiomark:after{display:block}
.Radio .Radiomark:after{left:6px;top:3px;width:6px;height:11px;border:solid #fff;border-width:0 2px 2px 0;transform:rotate(55deg)}

.Switch{position:relative;display:inline-block;width:50px;height:28px;border-radius:50px;margin:0}
.Switch input{opacity:0;width:0;height:0}
.Switch .slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#a5aab4;transition:0.4s;border-radius:60px}
.Switch .slider:before{position:absolute;content:"";height:22px;width:22px;left:3px;top:3px;background-color:#fff;transition:0.4s;border-radius:50%}
.Switch input:checked+.slider{background-color:#367dd6}
.Switch input:checked+.slider:before{transform:translateX(22px)}

.Button{width:350px; cursor: pointer; background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:18px;color:#fff;font-weight:700;padding:10px 0;border-radius:7px;transition:0.9s all ease-in-out;text-align:center;outline:0 !important; display: flex; align-items: center; justify-content: center;     background: #367dd6; }
.Button img{margin-left:7px;width:15px}
.Button:hover{background-position:700px 0;color:#fff}

.LoginArea{display:flex;min-height:100vh}
.LoginArea .LoginLeft{width:50%;background-color:rgb(86 224 224 / 15%);display:flex;align-items:center;justify-content:center}
.LoginArea .LoginLeft aside{width:55%;text-align:center}
.LoginArea .LoginLeft aside figure{width:240px;margin:0 auto 60px}
.LoginArea .LoginLeft aside video{ margin: 0 0 20px; width: 100%; }
.LoginArea .LoginLeft aside h3{font-size:23px;font-weight:700;margin:0 0 15px 0}
.LoginArea .LoginLeft aside p{font-size:14px;line-height:25px;font-weight:500;color:#7d7d7d}
.LoginArea .LoginRight{width:50%; padding: 50px 0; display:flex;align-items:center;justify-content:center; position: relative;}
.LoginArea .LoginRight aside{width:65%; position: relative; z-index: 9;}
.LoginArea .LoginRight aside h5.Step{font-size:13px;margin:0 0 15px 0;color:#000;font-weight:600}
.LoginArea .LoginRight aside h1{font-size:30px;font-weight:800;color:#000;margin:0 0 15px}
.LoginArea .LoginRight aside h6{font-size:14px;line-height:21px}
.LoginArea .LoginRight aside form{margin:30px 0 0 0}
.LoginArea .LoginRight aside form .form-group{position:relative}
.LoginArea .LoginRight aside form .form-group .Icon{position:absolute;top:40px;right:15px;width:20px}
.LoginArea .LoginRight aside form .form-group label{display:block;color:#000;font-weight:600;font-size:14px}
.LoginArea .LoginRight aside form .form-group label a{color:#1DA1F2;font-size:14px;font-weight:600;float:right}
.LoginArea .LoginRight aside form .form-group .form-control{height:auto;padding:10px 50px 10px 15px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px}
.LoginArea .LoginRight aside form .form-group select{-webkit-appearance:none;-moz-appearance:none;appearance:none}
.LoginArea .LoginRight aside form .form-group .Radio{margin: 0 0 20px 0; padding: 0 0 0 70px; font-size: 16px;}
.LoginArea .LoginRight aside form .form-group .Radio span.Credit{position: absolute; top: -5px; left: 30px; width: 22px;}
.LoginArea .LoginRight aside form .form-group .Radio input:checked~.Radiomark{ background-color: #fff; border: 1px solid #ddd;}
.LoginArea .LoginRight aside form .form-group .Radio .Radiomark:after{top: 0; left: 0; right: 0; bottom: 0; width: 12px; height: 12px; border: none; background-color: #1DA1F2; border-radius: 15px;  transform: inherit; margin: auto;}
.LoginArea .LoginRight aside form h5{text-align:right;margin:0 0 30px 0}
.LoginArea .LoginRight aside form h5 a{color:#12B2B3;font-size:14px;font-weight:600}
.LoginArea .LoginRight aside form h5 a:hover{color:#000}
.LoginArea .LoginRight aside form .form-group+button{margin-top:15px}
.LoginArea .LoginRight aside form button,
.LoginArea .LoginRight aside button{width:100%;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:18px;color:#fff;font-weight:700;padding:10px 0;border-radius:5px;transition:0.5s all ease-in-out;outline:0;display: flex; align-items: center; justify-content: center;}
.LoginArea .LoginRight aside form button img,
.LoginArea .LoginRight aside button img{width:16px;margin:0 0 0 7px}
.LoginArea .LoginRight aside form button:hover,
.LoginArea .LoginRight aside button:hover{background-position:0 90px}
.LoginArea .LoginRight aside h4{margin:30px 0 0 0;text-align:center;font-size:15px;color:#9393AA;font-weight:700}
.LoginArea .LoginRight aside h4 a{color:#12B2B3}
 
.LoginArea .LoginRight.LocationBox{ display: block; }
.LoginArea .LoginRight.LocationBox .Map{height:50vh;overflow:hidden;position:relative; margin: -50px 0 0 0;}
.LoginArea .LoginRight.LocationBox .Map .Search{position:absolute;top:50px;left:0;width:55%;right:0;margin:auto}
.LoginArea .LoginRight.LocationBox .Map .Search span{position:absolute;top:14px;width:15px;left:15px}
.LoginArea .LoginRight.LocationBox .Map .Search input{height:auto;padding:13px 15px 13px 40px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px}
.LoginArea .LoginRight.LocationBox .Form{height:50vh;align-items:center;display:flex;justify-content:center}
.LoginArea .LoginRight.LocationBox .Form aside form{margin:0}

.LoginArea .LoginRight .BackButton{position: absolute; cursor: pointer; right: 0; color: #000; font-weight: 600; font-size: 16px; top: -10px;line-height: 10px; display: flex; align-items: center;}
.LoginArea .LoginRight .BackButton i{ margin-right: 5px; }
.LoginArea .LoginRight .BackButton:hover{ color: #12b2b3; }

.ToolBox .ToolTip{position: absolute;top: -40px;width: 330px;background-color: #000;right: -90px;text-align: center;padding: 7px 10px;border-radius: 5px;color: #fff;display: none;}
.ToolBox:hover .ToolTip{display: block;}

.PayButton{display:flex!important;align-items:center;justify-content:space-between !important;padding:10px 15px!important}
.PayButton .Plan{font-size:11px;font-weight:500}
.PayButton .Plan .Price{display:block;font-size:15px;font-weight:800}

.Gender{display:inline-block;text-align:center;position:relative}
.Gender+.Gender{margin:0 0 0 50px}
.Gender input{opacity:0;width:60px;height:60px;border:2px solid #ddd;border-radius:18px;position:absolute;top:0;left:0;cursor:pointer;z-index:9}
.Gender figure{margin:0 0 10px 0;width:60px;height:60px;border:2px solid #ddd;border-radius:18px;display:flex;align-items:center;justify-content:center}
.Gender span{font-size:15px;color:#9393AA;font-weight:600}
.Gender input:checked~figure{background-color:#1DA1F2;border-color:#1DA1F2}
.Gender input:checked~figure img{filter:brightness(0) invert(1)}
.Gender input:checked~figure+span{color:#000}

.Medication{margin:0 0 40px 0}
.Medication article{position:relative;padding:0 0 0 80px;text-align:left;margin:0 0 35px 0; min-height: 110px;}
.Medication article figure{position:absolute;top:10px;left:0;margin:0; width: 70px;}
.Medication article h6{ font-size: 25px !important; color:#000;font-weight:700;margin:0 0 5px 0;line-height:inherit!important}
.Medication article p{font-size:15px}
.Medication a.MedicationOpen{background-color:#F0F0F0;display:block;padding:13px 20px;border-radius:7px;font-size:17px;color:#9393AA;font-weight:600;position:relative}
.Medication a.MedicationOpen:after{content:'\f105';font-family:'FontAwesome';position:absolute;right:20px}
.Medication .MedicationList{margin:10px 0 0 0}
.Medication .MedicationList span{background-color:#367dd6;margin:6px 5px 0 0;padding:5px 10px;display:inline-block;border-radius:5px;color:#fff;font-weight:600}
.Medication .MedicationList a{color:#fff;margin:0 0 0 9px;font-weight:700;font-size:14px}
 
.SurgeryMedication h5{font-size: 25px;color: #000;font-weight: 700;margin: 0 0 15px 0;line-height: inherit;}
.SurgeryMedication .form-control{height: auto;padding: 10px 15px 10px 15px;border: 1px solid #ddd;border-radius: 7px;box-shadow: none;font-weight: 600;font-size: 14px;}

.LoginArea .LoginRight aside form .ClickBox{margin:0 0 70px 0}
.LoginArea .LoginRight aside form .ClickBox .Click{display:flex;align-items:center;margin:0 0 20px 0}
.LoginArea .LoginRight aside form .ClickBox .Click .ClickIcon{width:55px;margin:0 20px 0 0}
.LoginArea .LoginRight aside form .ClickBox .Click h4{margin:0;color:#000;font-size:17px}

.LoginArea .LoginRight aside .Seccessreset{ text-align: center; }
.LoginArea .LoginRight aside .Seccessreset img{width: 300px; margin: auto; display: block;}
.LoginArea .LoginRight aside .Seccessreset h3{font-size: 23px; font-weight: 700; margin: 20px 0 15px 0;}
.LoginArea .LoginRight aside .Seccessreset p{font-size: 14px; line-height: 25px; font-weight: 500; color: #7d7d7d;}

.MemberShip{background-color:#F0F0F0;border-radius:20px;padding:30px;margin-top:40px}
.MemberShip h1{font-size:22px!important;margin:0 0 21px 0!important}
.MemberShip ul{margin-bottom:30px}
.MemberShip ul li{display:inline-block;width:49%;padding:0 0 0 20px;font-size:14px;color:#000;font-weight:600;position:relative;margin:0 0 12px 0}
.MemberShip ul li span{position:absolute;top:0;left:0}
.MemberShip .Price{display:flex}
.MemberShip .Price h3{font-size:15px;color:#000;width:50%}
.MemberShip .Price h3 span{font-weight:900;font-size:35px}
.MemberShip .Price button{width:50%!important}

#MemberSlider .carousel-control-prev{width:40px;height:40px;top:45%;border:1px solid #000;border-radius:50%;background-color:#fff;left:-60px}
#MemberSlider .carousel-control-next{width:40px;height:40px;top:45%;border:1px solid #000;border-radius:50%;background-color:#fff;right:-60px}
#MemberSlider .carousel-control-next img{transform:rotate(180deg)}

.SidenavArea{width:325px;background-color:#16171e; transition: 0.5s all ease-in-out; position:fixed;left:0;top:0;height:100%;overflow:auto}
.SidenavArea .SidenavHead{background-color:#1b3e6b;padding:8px 30px 8px;display:flex;align-items:center}
.SidenavArea .SidenavHead .Close{ display: none; color: #52a2f3; position: absolute; top: 10px; right: 10px; font-size: 17px; font-weight: 800; line-height: 24px; width: 25px; height: 25px; text-align: center; border-radius: 50%; background-color: #fff; }
.SidenavArea .SidenavHead figure{margin:0 10px 0 0;width:35px;height:35px;border-radius:50%;    overflow: hidden;}
.SidenavArea .SidenavHead figure img{ width: 100%; height: 100%; }
.SidenavArea .SidenavHead h4{color:#fff;font-weight:700;font-size:16px}
.SidenavArea .SidenavBody{padding:20px 20px 20px; background-color: #367dd6;}
.SidenavArea .SidenavBody article{display:flex;align-items:center;justify-content:space-between}
.SidenavArea .SidenavBody article aside h2{font-size:20px;font-weight:800;color:#000}
.SidenavArea .SidenavBody article aside h3{font-size:13px;color:#fff}
.SidenavArea .SidenavBody article aside h4{font-size:11px;margin:8px 0 3px 0;color:#000;font-weight:600}
.SidenavArea .SidenavBody ul{display:flex;margin:20px 0;justify-content:space-between}
.SidenavArea .SidenavBody ul li{text-align:center; position: relative;}
.SidenavArea .SidenavBody ul li+li:before{content:'';position:absolute;top:0;left:-7px;height:100%;width:1px;background-color:#d8d8d8}
.SidenavArea .SidenavBody ul li p{color:#000;font-weight:700;font-size:17px;line-height:30px}
.SidenavArea .SidenavBody ul li span{display:block;font-size:11px;font-weight:600;color:#fff}
.SidenavArea .SidenavBody a.Book{width:100%;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:18px;color:#fff;font-weight:700;padding:13px 0;border-radius:5px;transition:0.5s all ease-in-out;outline:0;display:block;text-align:center;     background: #367dd6;}
.SidenavArea .SidenavFooter ul li a{display:block;color:#fff;font-size:14px;padding:15px 10px 15px 15px;font-weight:600;position:relative}
.SidenavArea .SidenavFooter ul li a .Icon{position:absolute;left:15px;top:13px; display: none; font-size: 20px;}
.SidenavArea .SidenavFooter ul li.active a,
.SidenavArea .SidenavFooter ul li a:hover{background-color:#1DA1F2;     background: #367dd6; color:#fff}
.SidenavArea .SidenavFooter ul li .dropdown-toggle::after{content:'\f107';font-family:'FontAwesome';border:none;margin-left:0;font-weight:300;font-size:17px;line-height:10px;position:absolute;top:20px;right:20px}
.SidenavArea .SidenavFooter ul li.show .dropdown-toggle::after{transform:rotate(180deg)}
.SidenavArea .SidenavFooter ul li .dropdown-menu{position:relative!important;width:100%;transform:inherit!important;background-color:transparent;border:none;box-shadow:none;margin:0;padding:0;float:none}
.SidenavArea .SidenavFooter ul li .dropdown-menu li a{padding:15px 10px 15px 30px;color: #6D6E71;}
.SidenavArea .SidenavFooter ul li .dropdown-menu li a:hover{ color: #fff; }
/*.SidenavArea .SidenavFooter ul li.active .dropdown-menu{ display: block; }*/
.SidenavArea .SidenavFooter ul li.active .dropdown-menu li a{ background-color: #e1fafa;color: #6D6E71;  }
.SidenavArea .SidenavFooter ul li.active .dropdown-menu li.active a{ color: #1DA1F2;}
      
.Header{position:fixed;width:calc(100% - 325px);margin-left:325px;background-color:#367dd6;box-shadow:0 0 6px #ddd;display:flex;justify-content:space-between;padding:10px 25px;align-items:center;z-index: 10;}
.Header .HeaderIcon .Toggle{ border-radius:5px;cursor:pointer}
.Header .HeaderIcon .Toggle span{background-color:#fff;display:block;width:22px;height:2px;border-radius:1px}
.Header .HeaderIcon .Toggle span+span{margin:4px 0 0 0}
.Header .HeaderRight ul{display:flex;align-items:center}
.Header .HeaderRight ul li{margin:0 0 0 20px}
.Header .HeaderRight ul li a img{ filter: brightness(0) invert(1); }
.Header .HeaderRight ul li.Profile a{width: 30px; height: 30px; border-radius: 50px; overflow: hidden; display: block;}
.Header .HeaderRight ul li.Profile a img{ filter: inherit; }
.Header .HeaderRight ul li.Chat a{width: 35px; height: 35px; border: 2px solid #fff; border-radius: 50px; overflow: hidden; display: block;}
.Header .HeaderRight ul li.Chat a img{ filter: inherit; }

.Main-wrapper{margin-left:325px; height: 100vh;}
.Wrapper{display:inline-block;margin:50px 0 0 0;padding:20px;width:100%;min-height:calc(100vh - 50px);background-color:#2d2e3d}
 
.BreadcumBox{display:flex;margin:0 0 20px 0;position: relative;}
.BreadcumBox h5{font-weight:700;color:#fff}
.BreadcumBox ul{display:flex;align-items:center;border-left:1px solid #ddd;padding:0 0 0 15px;margin:0 0 0 15px}
.BreadcumBox ul li{margin:0 20px 0 0;position:relative}
.BreadcumBox ul li+li:before{content:'/';position:absolute;left:-12px;top:1px; color: #fff;}
.BreadcumBox ul li a{font-size:15px;color:#fff;font-weight:600}
.BreadcumBox ul li a img{width:20px; filter: brightness(0) invert(1);}
.BreadcumBox p{position: absolute; right: 20px; color: #000; font-weight: 700;}

.Welcome{background-color:#fff;padding:30px;border-radius:20px;box-shadow:0 0 10px #ddd;margin:0 0 30px 0}
.Welcome a.Sync{ background-image: linear-gradient( 45deg ,#12b2b3,#56e0e0); border: none; font-size: 16px; color: #fff; font-weight: 600; padding: 12px 40px; border-radius: 5px; transition: 0.5s all ease-in-out; outline: 0; display: block; text-align: center; float: right;}
.Welcome h4{color:#292929;font-weight:700;font-size:18px;margin:0 0 15px 0}
.Welcome h3{color:#1DA1F2;font-weight:700;margin:0 0 10px 0}
.Welcome p{color:#6D6E71;font-size:14px;line-height:23px}

.Pink{background-color:#EE466E}
.Orange{background-color:#FE9E42}
.Aqua{background-color:#38CBCC}
.DarkAqua{background-color:#38CBCC}

.DashboardArea{display:flex;flex-wrap:wrap;border:2px solid #fff;border-radius:7px;overflow:hidden;box-shadow:0 0 6px #c1c1c1;margin:0 0 30px 0}
.DashboardArea .DashboardCount{flex-grow:1;width:25%;border-bottom:2px solid #fff;border-right:2px solid #fff;overflow:hidden; max-width: 25%;}
.DashboardArea .DashboardCount a{ padding:50px 15px; display: block; }
.DashboardArea .DashboardCount:nth-child(4n){border-right:none}
/*.DashboardArea .DashboardCount:nth-last-child(-n+4){border-bottom:none}*/
.DashboardArea .DashboardCount h4{color: #000;font-weight: 800;font-size: 19px;margin: 0 0 15px 0;word-break: break-word;text-align: center;min-height: 40px;}
.DashboardArea .DashboardCount h5{color: #000;font-weight: 800;font-size: 22px;margin: 0 0 15px 0;word-break: break-word;text-align: center; }
.DashboardArea .DashboardCount h3{margin:0 0 20px 0;color:#fff;font-weight:700}
.DashboardArea .DashboardCount p{color:#fff;font-weight:600;font-size:13px}
.DashboardArea .DashboardCount a article{display:flex;justify-content:space-between}
.DashboardArea .DashboardCount a article aside{text-align:center}
.DashboardArea .DashboardCount a article aside p{font-size:15px;font-weight:500;font-family:'Poppins';color:#000}
.DashboardArea .DashboardCount a article aside span{font-size:20px;font-weight:700;color:#000}


.Appstore{display:flex;justify-content:space-between}
.Appstore h4{color:#fff;font-weight:700;font-size:16px;line-height:26px}
.Appstore aside{width:340px}
.Appstore aside a{display:inline-block;width:140px;margin:0 0 0 15px}

#PlanList{ display: none; }


.CommonArea{background-color:#fbfbfb;padding:20px;border-radius:10px;}
/* .CommonArea{background-color:#fff;padding:20px;border-radius:10px;box-shadow:0 0 10px #ddd} */
.CommonArea+.CommonArea{margin-top:25px}
.CommonArea .CommonHead{display:flex;align-items:center;margin:0 0 20px 0;position: relative;}
.CommonArea .CommonHead .Switch{position: absolute; right: 0;}
.CommonArea .CommonHead figure{margin:0 15px 0 0;width:25px}
.CommonArea .CommonHead h5{font-weight:700;font-size:18px}
.CommonArea .CommonHead h5 a{ text-decoration: underline; color: #1DA1F2;}
.CommonArea .CommonHead h5 a:hover{ color: #000; }
.CommonArea .CommonHead a.history{ width: 150px; background-image: linear-gradient(45deg,#12b2b3,#56e0e0); border: none; font-size: 16px; color: #fff; font-weight: 700; padding: 7px 0; border-radius: 7px; transition: 0.9s all ease-in-out; outline: 0; position: absolute; right: 10px; text-align: center; background: #367dd6;}
.CommonArea .CommonHead a.history:hover{ background-position: 300px 0px; }
.CommonArea .CommonBody{padding:0 0 0 40px;margin: 0px 0 0 0;}

.RenewalBox{display:flex;justify-content:space-between}
.RenewalBox .RenewalLeft h4{font-size:15px;font-weight:500;line-height:22px;margin:0 0 25px 0}
.RenewalBox .RenewalLeft h5{font-weight:700;font-size:18px;margin:0 0 25px 0;color:#5a5a5a}
.RenewalBox .RenewalLeft aside{display:flex;margin:0 0 30px 0;align-items:center}
.RenewalBox .RenewalLeft aside figure{margin:0 20px 0 0;width:40px}
.RenewalBox .RenewalLeft aside figcaption{width:300px}
.RenewalBox .RenewalLeft aside figcaption h6{font-weight:700;font-size:18px;margin:0 0 5px 0}
.RenewalBox .RenewalLeft aside figcaption h6 span{color:#a2a1a1;margin:0 0 0 10px;font-size:13px;font-weight:600}
.RenewalBox .RenewalLeft aside figcaption p{font-size:15px;font-weight:600;color:#505050;letter-spacing:.5px}
.RenewalBox .RenewalLeft aside a{color:#12B2B3;font-weight:700;font-size:15px}

.ProfileBox{display:flex;margin:0 0 20px 0}
.ProfileBox figure{margin:0 20px 0 0;text-align:center}
.ProfileBox figure span.ProfileIcon{display:block; position: relative; width:90px;height:90px;border-radius:50%;overflow:hidden;margin:0 0 10px 0}
.ProfileBox figure span.ProfileIcon img{width:100%}
.ProfileBox figure span.ProfileIcon .Camera{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: #00000080; display: none; }
.ProfileBox figure span.ProfileIcon .Camera i{ color: #fff; font-size: 25px; line-height: 90px;}
.ProfileBox figure span.ProfileIcon .Camera input{position: absolute; top: 0; left: 0;  width: 100%; height: 100%; cursor: pointer; opacity: 0;}
.ProfileBox figure span.ProfileIcon:hover .Camera{ display: block; }

.ProfileBox figure a{font-weight:700;color:#fff !important}
.ProfileBox figcaption{padding:20px 0 0 0}
.ProfileBox figcaption h3{font-weight:700;font-size:23px;margin:0 0 6px 0; color: #fff;}
.ProfileBox figcaption p{font-weight:600;font-size:14px; color: #fff;}

.CommonButon{margin:30px 0 30px 0;display:flex;align-items:center}
.CommonButon .Disable{ background: #9393AA; }
.CommonButon h4{margin:0 0 0 80px}
.CommonButon h4 a{color:#5ab7b8;font-size:18px;font-weight:600}
.CommonButon h4 a i{ margin-right: 10px; }    

.CommonForm .form-group{position:relative}
.CommonForm .form-group label{display:block;color:#000;font-weight:600;font-size:14px}
.CommonForm .form-group .form-control{height:auto;padding:10px 50px 10px 15px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px}
.CommonForm .form-group textarea.form-control{height:auto}
.CommonForm .form-group .Icon{position:absolute;top:40px;right:15px;width:20px}
.CommonForm .form-group .Icon img{width:20px}
.CommonForm .form-group .Icon a{font-weight:600;color:#5cbbbc;font-size:13px;margin:3px 0 0 0;display:block}

.PrivacyBox p{margin:0 0 10px 0;font-size:14px;font-weight:500;line-height:27px}

.SwitchUser{padding:20px}
.SwitchUser ul li+li{padding:15px 0 0 0;margin:15px 0 0 0;border-top:1px solid #eaeaea}
.SwitchUser ul li .CheckBox{padding:10px 30px 0 75px;min-height:55px;font-size:18px;font-weight:700}
.SwitchUser ul li .CheckBox figure{position:absolute;top:0;left:0;width:55px;height:55px;margin:0}
.SwitchUser ul li .CheckBox .Checkmark{top:15px}

.RewardsArea .nav-tabs{border:none;margin:0 0 30px 0}
.RewardsArea .nav-tabs .nav-item{margin:0 30px 0 0}
.RewardsArea .nav-tabs .nav-item .nav-link{border:none;padding:0 0 9px 0;background-color:transparent;color:#fff;font-weight:700;font-size:15px;position:relative}
.RewardsArea .nav-tabs .nav-item .nav-link:after{content:'';background-color:transparent;position:absolute;bottom:0;width:50px;height:2px;left:0;right:0;margin:auto}
.RewardsArea .nav-tabs .nav-item .nav-link.active{color:#54a7f4}
.RewardsArea .nav-tabs .nav-item .nav-link.active:after{background-color:#54a7f4}

.RewardsBox{background-color:#fff;text-align:center;padding:25px;margin:0 0 30px;border-radius:15px}
.RewardsBox figure img{width:75px}
.RewardsBox h3{font-size:14px;color:#000;font-weight:700;line-height:20px;margin:0 0 5px 0}
.RewardsBox p{color:#616161;font-size:13px;font-weight:500;margin:0 0 10px 0}
.RewardsBox span{background-color:#55a8f4;color:#fff;padding:5px 20px;display:inline-block;border-radius:5px;line-height:14px;font-size:11px}
.RewardsBox span strong{display:block;font-size:13px}

.Setting ul li{display:flex;justify-content:space-between;align-items:center}
.Setting ul li+li{margin:15px 0 0 0;border-top:1px solid #ddd;padding:15px 0 0 0}
.Setting ul li span.Title{font-size:15px;font-weight:600;color:#000}

.NotificationArea{padding:25px 25px 25px 40px}
.NotificationArea ul li{display:flex;opacity:.6;position:relative;border-bottom:1px solid #ddd;padding:0 0 15px 0;margin:0 0 15px 0}
.NotificationArea ul li:last-child{border:none;margin:0}
.NotificationArea ul li.active{opacity:1}
.NotificationArea ul li.active:before{content:'';position:absolute;width:8px;height:8px;background-color:red;left:-20px;top:20px;border-radius:50%}
.NotificationArea ul li figure{margin:0 20px 0 0;width:50px;height:50px}
.NotificationArea ul li h3{font-size: 22px; margin: 0 0 5px 0; line-height: 20px; font-weight: 700;}
.NotificationArea ul li h6{font-size:16px;margin:0;line-height:20px}
.NotificationArea ul li p{color:#000}

.VaccinationArea{background-color:#fff;padding:20px;border-radius:10px;box-shadow:0 0 10px #ddd;min-height:300px}
.VaccinationArea h1{margin:0 0 15px 0;font-size:20px;font-weight:700}
.VaccinationHead{margin-bottom:20px}
.VaccinationBox{max-width:225px}
.VaccinationBox .VaccinationDate{border:2px solid #ddd;display:flex;border-radius:7px;padding:15px 20px;margin:0 0 20px 0;position:relative;justify-content:space-between}
/* .VaccinationBox .VaccinationDate:before{content:'';position:absolute;width:1px;height:30px;left:33%;top:0;bottom:0;background-color:#adadad;margin:auto}
.VaccinationBox .VaccinationDate:after{content:'';position:absolute;width:1px;height:30px;right:33%;top:0;bottom:0;background-color:#adadad;margin:auto} */

.VaccinationBox .VaccinationDate input{width:100%;border:none;box-shadow:none;outline:0;text-align:center;font-size:15px;font-weight:600;color:#000;letter-spacing:.5px;margin:0 0 0 0;max-width:inherit}
.VaccinationBox h3{margin:0 0 15px 0;font-size:16px;font-weight:700}
.VaccinationBox h4{margin:0 0 20px 0;font-size:16px;font-weight:700}
.VaccinationBox h4 img{width:23px;margin:0 7px 0 0}
.VaccinationBox p{font-size:14px;font-weight:600;line-height:23px;color:#505050;margin:0 0 30px 0}
.VaccinationBox a{width:100%;background-color:#F0F0F0;display:block;color:#888;text-align:center;padding:15px 0;font-weight:600;font-size:15px;border-radius:10px}
.VaccinationBox a img{width:17px;margin:0 6px 0 0}
.VaccinationBox .CommonButon{margin:50px 0 -90px 0}

.TableHead{background-color:#EBEBEE;margin:0 0 20px 0;padding:20px}
.TableHead form{position:relative}
.TableHead form span{position:absolute;top:12px;left:17px;font-size:16px;color:#8a8a8a}
.TableHead form input{background-color:#fff;border:none;padding:13px 20px 13px 45px;border-radius:50px;font-size:14px;font-weight:600;min-width:250px}

.TableList table{ margin: 0; }
.TableList tr th{text-align:center;font-size:15px;color:#848484;border-bottom:1px solid #dee2e6;border-top:none;vertical-align:middle;padding:5px 10px 5px 10px}
.TableList tr td{text-align:center;vertical-align:middle;border-bottom:1px solid #dee2e6;border-top:none;padding:10px 10px}
.TableList tr td figure{margin:auto;border:1px solid #ddd;border-radius:8px;width:40px;height:40px;padding:2px}
.TableList tr th .CheckBox,
.TableList tr td .CheckBox{position:relative;padding:0;width:26px;height:25px}
.TableList tr td .Zoom{color:#5bbabb;font-weight:600}
.TableList tr td .View{font-weight:600;color:#828282;display: flex; align-items: center; justify-content: center;}
.TableList tr td .View i{ color: #1965d8; font-size: 17px; margin-right: 5px; }
    
.AvatarArea h3{font-size:18px;font-weight:700;margin:0 0 10px}
.AvatarLeft .AvatarImages img{width:55%}
.AvatarLeft .AvatarGender{position:absolute;bottom:40px;left:40px}

.AvatarArea .AvatarLeft .AvatarImages{text-align:center; margin: auto; position: relative; } 
.AvatarBox .AvatarList{ height:450px;overflow:auto;padding:0 20px 0 0 }
.AvatarBox .AvatarList .CheckBox+.CheckBox{ margin-top: 15px; }

.AvatarLeft .AvatarPostion {position: absolute;right: 0;bottom: 0px;z-index: 9;width: 95%;left: 0;margin: auto;}

ul.Select{ position: relative; z-index: 1; display: flex; justify-content: space-between; }
ul.Select li{position:relative;     width: 120px;}
ul.Select li input{position:absolute;width:100%;height:100%;opacity:0;cursor:pointer;left:0}
ul.Select li span{background-color:rgb(147 147 170 / 50%);color:#fff;display:block;text-align:center;padding:11px 0;border-radius:5px;font-size:14px;font-weight:600}
ul.Select li input:checked~span{background-color:#1DA1F2}

.MaleFront,
.MaleBack { position: relative; z-index: 2; }

.CommonAvatar{display: flex;justify-content: center;position: relative;}
.CommonAvatar .AvatarSymptoms{position: relative;}
.CommonAvatar .AvatarSymptoms img{width: 55%;}
.CommonAvatar .AvatarSymptoms .MaleCommon{position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
.CommonAvatar .AvatarSymptoms .MaleCommon img{position: absolute;top: 0;left: 0;right: 0;margin: auto;height: 100%;opacity: 0;}

.LabTable table tr th{background-color:#dedede;padding:10px 15px}
.LabTable table tr td{padding:12px 15px;font-size:15px;font-weight:500}

.Significant h3{font-weight:700;font-size:23px;margin:0 0 10px 0; color: #fff;}
.Significant p{color:rgb(255, 255, 255);font-size:16px}
.Significant aside{display:flex;justify-content:space-between;margin:20px 0 15px 0;align-items:center;font-size:14px}
.Significant aside h4{font-weight:700;font-size:20px; color: #fff;}
.Significant aside img{width:20px;margin:0 8px 0 0}
.Significant aside div{ color: #fff; }
.Significant aside a{margin:0 0 0 10px;font-weight:600;color:#fff;text-decoration:underline;font-style:italic}
.Significant .FilterBox{display:flex}
.Significant .FilterBox .form-group{margin:0 20px 0 0}
.Significant .FilterBox .form-group label{display:block;color:#fff;font-weight:600;font-size:14px}
.Significant .FilterBox .form-group .form-control{height:auto;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:500;font-size:14px;min-width:225px}
.Significant .FilterBox .form-group button{background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:17px;color:#fff;font-weight:700;padding:9px 40px;border-radius:7px;transition:0.9s all ease-in-out;outline:0;text-align:center}

.AppointmentBook .CommonForm .form-group .Icon{width:auto}
.AppointmentBook .CommonForm .form-group .Icon a{color:#1DA1F2}
.AppointmentBook .CommonForm .form-group .Radio{width:100%}
.AppointmentBook .CommonForm .form-group .Radio label img{width:15px;margin:0 5px 0 0}
.AppointmentBook .CommonForm .form-group .Radio .form-control{position:relative;width:100%;opacity:1}
.AppointmentBook .CommonForm .form-group .Radio .Insurance{padding:10px 50px 10px 15px;border:1px solid #ddd;position:relative;border-radius:7px;text-align:center}
.AppointmentBook .CommonForm .form-group .Radio .Insurance i{color:#1DA1F2;margin:0 5px 0 0}
.AppointmentBook .CommonForm .form-group .Radio .Insurance span{font-size:11px;color:#9e9e9e}
.AppointmentBook .CommonForm .form-group .Radio .Insurance input{position:absolute;top:0;left:0;opacity:0;height:100%;padding:0;cursor:pointer;width:100%}
.AppointmentBook .CommonForm .concernBox{display:flex}
.AppointmentBook .CommonForm .concernBox span.Upload{border:2px solid #ddd;width:120px;height:115px;display:flex;align-items:center;justify-content:center;border-radius:15px;position:relative}
.AppointmentBook .CommonForm .concernBox span.Upload img{width:40px}
.AppointmentBook .CommonForm .concernBox span.Upload input{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}
.AppointmentBook .CommonForm .concernBox span.Image{border:2px solid #ddd;width:120px;height:115px;border-radius:15px;margin:0 0 0 10px;position:relative;display:flex;align-items:center;justify-content:center;overflow:hidden}
.AppointmentBook .CommonForm .concernBox span.Image a{position:absolute;top:5px;right:5px;width:20px;height:20px;background-color:#ddd;text-align:center;border-radius:15px;cursor:pointer}
.AppointmentBook .CommonForm .concernBox span.Image img{width:116px;border-radius:15px}
.AppointmentBook .CommonForm .DiscomfortAvatar+.Discomfort{margin-left:20px;display:flex;align-items:center}
.AppointmentBook .CommonForm .form-group .AcuteBox{position:relative}
.AppointmentBook .CommonForm .form-group .AcuteBox p{color:#000;font-weight:600;font-size:14px;position:relative;border:2px solid #5cbbbc;padding:15px 30px 15px 20px;border-radius:6px;cursor:pointer; min-height: 50px;}
.AppointmentBook .CommonForm .form-group .AcuteBox p:after{content:'\f107';font-family:'FontAwesome';border:none;margin-left:0;font-weight:500;font-size:23px;line-height:10px;position:absolute;top:20px;right:15px}
.AppointmentBook .CommonForm .form-group .AcuteBox .AcuteList{position:absolute;     z-index: 10; width:100%;left:0;top:65px;padding:15px;background-color:#fff;box-shadow:0 0 4px #ddd;border-radius:8px;}
.AppointmentBook .CommonForm .form-group .AcuteBox .AcuteList ul{height:270px;overflow:auto;padding:0 15px 0 0}
.AppointmentBook .CommonForm .form-group .AcuteBox ul li{display:flex;align-items:center;justify-content:space-between}
.AppointmentBook .CommonForm .form-group .AcuteBox ul li+li{margin-top:15px}
.AppointmentBook .CommonForm .form-group .AcuteBox ul li .Title{color:#1DA1F2;font-size:15px;font-weight:500;    cursor: pointer;}
.AppointmentBook .CommonForm .form-group .AcuteBox ul li .AcuteIcon{cursor:pointer;position:relative;top:auto;right:auto;width:40px}
.AppointmentBook .CommonForm .form-group .AcuteBox a.AddSymptoms{background-color:#F0F0F0;color:#9393AA;display:block;text-align:center;padding:15px 0;font-size:16px;font-weight:700;border-radius:6px}
.AppointmentBook .CommonForm .form-group .AcuteBox .AcuteSelect{ position: relative; }
.AppointmentBook .CommonForm .form-group .AcuteBox .AcuteSelect:after{content:'\f107';font-family:'FontAwesome';border:none;margin-left:0;font-weight:500;font-size:23px;line-height:10px;position:absolute;top:20px;right:15px}
.AppointmentBook .CommonForm .form-group .AcuteBox .AcuteSelect select{color: #000;font-weight: 600;font-size: 14px;position: relative;border: 2px solid #5cbbbc;padding: 15px;border-radius: 6px;cursor: pointer;width: 100%;-webkit-appearance: none;}
.AppointmentBook .CommonForm .UploadReportLeft .Medication{margin:0 0 20px 0}
.AppointmentBook .CommonForm .UploadReportLeft .Medication article{margin:0}
.AppointmentBook .CommonForm .UploadReportLeft .Upload{background-color:#F0F0F0;color:#9393AA;display:block;text-align:center;padding:15px 0;font-size:16px;font-weight:700;border-radius:6px;position:relative;border:2px solid #5bb9ba}
.AppointmentBook .CommonForm .UploadReportLeft .Upload img{color:#1DA1F2;font-size:21px;margin:0 10px 0 0;display:inline-block}
.AppointmentBook .CommonForm .UploadReportLeft .Upload input{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}
.AppointmentBook .CommonForm .UploadReport .CommonHead{min-height:86px;margin:0}
.AppointmentBook .CommonForm .UploadReport .Upload{background-color:#F0F0F0;color:#9393AA;display:block;text-align:center;padding:15px 0;font-size:16px;font-weight:700;border-radius:6px;position:relative;border:2px solid #1DA1F2}
.AppointmentBook .CommonForm .UploadReport .Upload i{color:#1DA1F2;font-size:21px;margin:0 10px 0 0;display:inline-block}
.AppointmentBook .CommonForm .UploadReport .Upload input{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}

.AlternativeBox{height:175px}

.NoFound{text-align:center;margin:50px 0}
.NoFound p{font-weight:600;color:#000;font-size:18px}

.SymptomsRange{margin:0px 0 0 0; }
.SymptomsRange label{margin:0 0 5px 0}
.SymptomsRange ul{display:flex}
.SymptomsRange ul li{width:40px; position: relative; height:40px;color:#000;font-weight:700;line-height:40px;text-align:center;font-size:16px}
.SymptomsRange ul li input{ position: absolute; top: 0;  left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;}
.SymptomsRange ul li span{ display: block; transition: 0.5s all ease-in-out;}
.SymptomsRange ul li input:checked ~ span{transform: scale(1.2); z-index: 5;  position: relative;}
.SymptomsRange ul li:nth-child(1) span {background-color:rgb(29 161 242 / 10%)}
.SymptomsRange ul li:nth-child(2) span {background-color:rgb(86 224 224 / 20%)}
.SymptomsRange ul li:nth-child(3) span {background-color:rgb(18 178 179 / 30%)}
.SymptomsRange ul li:nth-child(4) span {background-color:rgb(18 178 179 / 40%)}
.SymptomsRange ul li:nth-child(5) span {background-color:rgb(18 178 179 / 60%)}
.SymptomsRange ul li:nth-child(6) span {background-image:linear-gradient(90deg,#71d1d1,#ee9a96)}
.SymptomsRange ul li:nth-child(7) span {background-color:rgb(221 53 46 / 50%)}
.SymptomsRange ul li:nth-child(8) span {background-color:rgb(221 53 46 / 60%)}
.SymptomsRange ul li:nth-child(9) span {background-color:rgb(221 53 46 / 70%)}
.SymptomsRange ul li:nth-child(10) span {background-color:#DD352E}

.Filter{text-align:right}
.Filter a{background-color:#F0F0F0;color:#52a2f3;text-align:center;padding:10px 30px;font-size:15px;font-weight:600;border-radius:6px;position:relative;border:2px solid #5bb9ba;display:inline-block}
.Filter a i{color:#0000005c;font-size:18px;margin:0 3px 0 0}

.DoctorBox{background-color:#fff;border-radius:15px;margin:20px 0 0 0;padding:15px}
.DoctorBox .DoctorHead{position:relative;padding:0 30px 0 90px;min-height:90px}
.DoctorBox .DoctorHead figure{width:75px; height: 75px; overflow: hidden; box-shadow:0 4px 10px #ddd;border-radius:25px;position:absolute;left:0;margin:0}
.DoctorBox .DoctorHead figure img{ width: 100%; height: 100%; }
.DoctorBox .DoctorHead h3{font-weight: 700;font-size: 16px;margin: 0 0 10px 0;color: #52a2f3;max-width: 230px;}
.DoctorBox .DoctorHead h3 span{width:10px;height:10px;background-color:#5bb9ba;display:inline-block;border-radius:50%;margin:0 0 0 5px}
.DoctorBox .DoctorHead ul li{display:inline-block;margin:0 20px 10px 0;color:#000;font-size:15px;font-weight:500}
.DoctorBox .DoctorHead ul li img{margin:0 6px 0 0}
.DoctorBox .DoctorHead .Radio{width:20px;height:20px;padding:0;position:absolute;top:0;right:0}
.DoctorBox .DoctorBody{border-top:1px solid #ddd;padding:15px 0;min-height: 122px;}
.DoctorBox .DoctorBody h6{display:flex;align-items:center;margin:0 0 10px 0}
.DoctorBox .DoctorBody h6 i{color:#FE9870;font-size:20px;margin:0 10px 0 0}
.DoctorBox .DoctorBody h6 strong{color:#000000;font-size:20px;margin:0 10px 0 0}
.DoctorBox .DoctorBody h6 span{font-weight:600;color:#5f5f5f}
.DoctorBox .DoctorBody p{color:#000;font-weight:500;font-size:15px;line-height:27px}
.DoctorBox .DoctorBody a{ float: right; font-weight: 600; color: #1DA1F2; font-size: 14px; }
.DoctorBox .DoctorFooter{display:flex;justify-content:space-between;align-items:center}
.DoctorBox .DoctorFooter ul{display:flex;align-items:center}
.DoctorBox .DoctorFooter ul li{display:flex;align-items:center;margin-right:10px;font-size:16px;color:#000}
.DoctorBox .DoctorFooter ul li:nth-child(1) img{max-width: 40px;width: auto;height: auto;overflow: inherit;border-radius: 0;}
.DoctorBox .DoctorFooter ul li img {width: 30px;height: 30px; overflow: hidden;border-radius: 50%;}
.DoctorBox .DoctorFooter ul li strong{margin:0 5px 0 0}
.DoctorBox .DoctorFooter a.Arrow{color:#9393AA;font-size:25px;font-weight:800}

.CalenderLeft ol{display:flex}
.CalenderLeft ol li{flex-grow:1;width:calc(100% / 7);text-align:center;padding:0 0 11px 0;font-size:13px;font-weight:600;color:#bbb}
.CalenderLeft h6{margin:0 0 15px 0;color:#9393AA;font-weight:600;font-size:13px}
.CalenderLeft ul{display:flex;flex-wrap:wrap}
.CalenderLeft ul li{flex-grow:1;width:calc(100% / 7);background-color:#12B2B3;position:relative;margin:0 0 5px 0}
.CalenderLeft ul li input{position:absolute;left:0;width:100%;height:100%;opacity:0;cursor:pointer}
.CalenderLeft ul li span{color:#fff;font-size:15px;display:block;text-align:center;font-weight:500;padding:10px 0}
.CalenderLeft ul li input:checked~span{background-color:#FE9870}
.CalenderLeft ul li.Disable{background-color:#fff}
.CalenderLeft ul li.Disable span{background-color:#fff;color:#E0E0E0}
.CalenderLeft ul li.Color{background-color:#fff}
.CalenderLeft ul li.Color span{color:#000;background-color:#fff}
.CalenderRight h6{margin:0 0 30px 0;font-weight:700;color:#000}
.CalenderRight .CalenderRightBox {height: 330px;overflow: auto;padding: 0 20px 0px 0px;}

.AppointmentDeatils .DoctorDetails{margin:0 0 30px 0}
.AppointmentDeatils .DoctorDetails .DoctorConsult{padding:0}
.AppointmentDeatils .DoctorDetails .DoctorConsult .DoctorBody aside{max-width:300px}
.AppointmentDeatils .card{background-color:#fff;border-radius:10px;box-shadow:0 0 10px #ddd;margin:0 0 30px 0;border:none; overflow: hidden;}
.AppointmentDeatils .card .card-header{border:none;background-color:transparent;padding:20px 10px 20px 50px;cursor:pointer;position:relative; display: flex; justify-content: space-between;}
.AppointmentDeatils .card .card-header:before{content:'\f106';font-family:'FontAwesome';border:none;margin-left:0;font-weight:700;font-size:22px;line-height:10px;position:absolute;top:25px;left:20px;transition:0.5s all ease-in-out}
.AppointmentDeatils .card .card-header[aria-expanded="false"]:before{transform:rotate(180deg)}
.AppointmentDeatils .card .card-header h4{font-weight:700;font-size:18px}
.AppointmentDeatils .card .card-header .More{font-size: 16px; font-weight: 700; color: #000; border-bottom: 2px solid #1f1f1f;}
.AppointmentDeatils .card .card-body{padding:0 0 15px 0}
.AppointmentDeatils .TableBox table{margin:0}
.AppointmentDeatils .TableBox .table-striped tr:nth-child(odd){background:#F5F5F5}
.AppointmentDeatils .TableBox table tr td{border:none;padding:10px 15px;font-weight:500;color:#848484;font-size:15px}
.AppointmentDeatils .TableBox table tr td i{ word-break: break-all; }
.AppointmentDeatils .TableBox table tr th{border:none;padding:10px 15px;color:#666;font-size:15px;background:#F5F5F5}
.AppointmentDeatils .TableBox table tr th:first-child,
.AppointmentDeatils .TableBox table tr td:first-child{padding:10px 15px 10px 50px;border-right:1px solid #d2d0d0; width: 180px;}
.AppointmentDeatils .TableBox table tr th:nth-child(1){width:150px}
.AppointmentDeatils .TableBox table tr td span.Good{display:inline-block;border:1px solid #42B25C;color:#42B25C;font-weight:600;padding:4px 0;width:80px;text-align:center}
.AppointmentDeatils .TableBox table tr td span.Bad{display:inline-block;border:1px solid #dc3545;color:#DC3545;font-weight:600;padding:4px 0;width:80px;text-align:center}
.AppointmentDeatils .Symptomsappointment{display:flex;margin:0 0 20px 0}
.AppointmentDeatils .Symptomsappointment .SymptomsLeft{width:50%;max-width:400px;margin:0 20px 0 0}
.AppointmentDeatils .Symptomsappointment .SymptomsRight{width:50%;max-width:350px}
.AppointmentDeatils .Symptomsappointment .SymptomsRight aside{margin:0px 0 15px 0}
.AppointmentDeatils .Symptomsappointment .SymptomsRight aside p{color:#000;margin:0 0 5px 0;font-size:13px;font-weight:500}
.AppointmentDeatils .Symptomsappointment .SymptomsRight aside .ProgressBar{width:100%;height:15px;background-color:#E9ECEF;box-shadow:0 2px 4px #ddd}
.AppointmentDeatils .Symptomsappointment .SymptomsRight aside .ProgressBar span{display:block;height:100%}
.AppointmentDeatils .Symptomsappointment .SymptomsRight aside .ProgressBar .progress-bar--inner{ height: 100%; }
.AppointmentDeatils .Symptomsappointment .SymptomsLeft .TableBox table tr td{color:#000;padding-top:12px;padding-bottom:12px}
.AppointmentDeatils .PatientLeft{ margin-bottom: -15px; }
.AppointmentDeatils .PatientRight{text-align: center;}
.AppointmentDeatils .PatientRight figure{width: 200px; height: 200px;border-radius: 100%;margin: 0 auto 10px;overflow: hidden;}
.AppointmentDeatils .PatientRight figure img{width: 100%;}
.AppointmentDeatils .PatientRight h3{font-size: 20px;font-weight: 700;color: #5bbabb;margin: 0 0 5px 0;}
.AppointmentDeatils .PatientRight p{margin: 0 0 5px;font-size: 13px;font-weight: 600;}
.AppointmentDeatils .PatientRight h4{font-size: 16px;font-weight: 700;color: #5bbabb;margin: 0;}

.CheckinArea{max-width:430px}
.CheckinArea .CheckinTitle{margin:0 0 20px 0;background-color:#1DA1F2;text-align:center;padding:18px 0;border-radius:7px}
.CheckinArea .CheckinTitle h4{color:#fff;font-weight:700;font-size:20px}
.CheckinArea .CheckEmoji{background-color:#fff;padding:40px 0 0 0;border-radius:10px;box-shadow:0 0 10px #ddd;text-align:center;overflow:hidden;margin-bottom:30px}
.CheckinArea .CheckEmoji h3{font-size:18px;font-weight:700;margin:0 0 20px 0}
.CheckinArea .CheckEmoji ul{margin:30px 0 40px 0}
.CheckinArea .CheckEmoji ul li{display:inline-block;margin:0 10px;position:relative}
.CheckinArea .CheckEmoji ul li input{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer;z-index:9}
.CheckinArea .CheckEmoji ul li span{width:25px;height:25px;border:1px solid #ddd;display:block;border-radius:50%;width:25px;height:25px;border:1px solid #ddd;display:block;border-radius:50%}
.CheckinArea .CheckEmoji ul li span:after{content:'';position:absolute;top:0;left:0;width:15px;height:15px;background-color:#fff;border-radius:15px;margin:auto;right:0;bottom:0}
.CheckinArea .CheckEmoji ul li input:checked~span{border-color:#5bbabb}
.CheckinArea .CheckEmoji ul li input:checked~span:after{background-color:#5bb9ba}
.CheckinArea .CheckEmoji h6{background-color:#1DA1F2;text-align:center;padding:18px 0;color:#fff;margin-top:0;font-weight: 700; font-size: 20px}
.CheckinArea .Insight article{padding:0 0 0 60px;margin:20px 0 0 0}
.CheckinArea .Insight article figure{position:absolute;bottom:0;left:0;width:50px;margin:0}
.CheckinArea .Insight article ul.nav-tabs{border:none;margin:0 0 20px 0}
.CheckinArea .Insight article ul.nav-tabs li{margin:0;width:50%}
.CheckinArea .Insight article ul.nav-tabs li a{border:none;padding:0 0 8px 0;display:inline-block;font-size:16px;color:rgb(102 102 102 / 60%);font-weight:700;border-bottom:1px solid #fff}
.CheckinArea .Insight article ul.nav-tabs li a.active{color:#1DA1F2;border-color:#1DA1F2}
.CheckinArea .form-control{outline:0;box-shadow:none;border:2px solid #ddd;padding:8px 15px;height:auto;border-radius:7px;font-size:18px;font-weight:600}
.CheckinArea .CommonButon button{width:430px}
.CheckinArea .CommonButon button:hover{background-position:860px 0}
.CheckinArea h1.Title{margin:0 0 30px 0;font-size:23px;font-weight:700}
.CheckinArea .BodyPart h6{margin:0 0 20px 0;color:#000}

.CheckinArea .BodyPart h6 a{border:2px solid #ddd;border-radius:5px;background-color:#f0f0f0;padding:15px 50px;color:#676767;font-weight:600;font-size:15px;display:block;text-align:center}
.CheckinArea .BodyPart h6 a i{margin-left:10px}
.CheckinArea .BodyPart ul{display:flex}
.CheckinArea .BodyPart ul li{position:relative;border:1px solid #f5f5f5;margin:0 0 0 0;padding:10px;border-radius:5px;display:flex;align-items:center;width:100%}
.CheckinArea .BodyPart ul li .Avatar{display:flex}
.CheckinArea .BodyPart ul li .Avatar svg{width:100px}.CommonAvatar svg .active{opacity:2}
.CheckinArea .BodyPart ul li .AvatrPart{margin:0 0 0 35px}
.CheckinArea .BodyPart ul li .AvatrPart ol li{display:block;color:#000;font-weight:600;font-size:14px;padding:0;margin:0 0 5px 0;border:none}
.CheckinArea .BodyPart ul li img{width:100%;height:100%}
.CheckinArea .BodyPart ul li input{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;cursor:pointer}
.CheckinArea .BodyPart ul li span{position:absolute;top:5px;right:5px;border:1px solid #fff;display:block;width:20px;height:20px;border-radius:50%}
.CheckinArea .BodyPart ul li input:checked~span{background-color:#5ab7b8}
.CheckinArea .BodyPart ul li input:checked~span:after{content:'';left:7px;top:3px;width:5px;height:9px;border:solid #fff;border-width:0 2px 2px 0;transform:rotate(50deg);position:absolute}
.CheckinArea .CheckinDoctor{background-color:#fff;padding:70px 20px;border-radius:10px;box-shadow:0 0 10px #ddd;text-align:center}
.CheckinArea .CheckinDoctor figure{width:115px;box-shadow:0 4px 10px #ddd;border-radius:32px;margin:0 auto 40px}
.CheckinArea .CheckinDoctor figure img{width:100%}
.CheckinArea .CheckinDoctor h4{font-size:18px;font-weight:700;color:#000}
.CheckinArea .CommonButon{display:block}
.CheckinArea .CommonButon h4{margin:30px 0 0 0;text-align:center}
.CheckinArea .CommonButon h4 a{font-size:20px;color:#1DA1F2;cursor: pointer; }

#SecondSymtoms,
.SecondSymptoms{ display: none; }


/* a.Edit{position:absolute;right:0;background-color:#e1fafa;padding:7px 20px;border-radius:5px;color:#000;font-weight:600} */

.CheckinHistory{background-color:#fff;border-radius:15px;margin:0 0 30px 0;padding:0;box-shadow:0 0 3px #ddd;min-height:435px}
.CheckinHistory aside{border-bottom:1px solid #ddd;padding:15px 20px}
.CheckinHistory aside:last-child{border:none}
.CheckinHistory aside p{display:flex}
.CheckinHistory aside p+p{margin-top:10px}
.CheckinHistory aside p label{margin:0 15px 0 0;width:145px;font-size:15px;font-weight:700;position:relative;color:#000}
.CheckinHistory aside p label:after{content:':';position:absolute;right:0}
.CheckinHistory aside p span{font-size:16px;width:calc(100% - 160px)}
.CheckinHistory aside p span.Blue{color:#1DA1F2}
.CheckinHistory aside p span.Green{color:#42b25c}
.CheckinHistory aside .AppointmentDeatils .Symptomsappointment{margin:10px 0 0 0}
.CheckinHistory aside .AppointmentDeatils .Symptomsappointment .SymptomsLeft{width:100%;margin:0;max-width:inherit}

.ECGBox{padding:0 0 0 50px}
.ECGBox aside{padding:0 0 15px}
.ECGBox aside+aside{border-top:2px solid #ddd;padding:15px 0 15px 0}
.ECGBox aside img{width:25px;margin:0 10px 0 0}
.ECGBox aside em{font-size:15px;font-weight:500;color:#2e81d6;margin:0 15px 0 0;word-break:break-all}
.ECGBox aside a{margin:0 15px 0 0;font-weight:600;color:#000;font-size:14px}
.ECGBox aside span{font-size:15px;color:#38a5a5;font-weight:600;margin:0 15px 0 0}
.ECGBox aside span i{font-size:16px}

.ModalBox #DetailsModal .modal-dialog{margin: 20px auto; max-width: 700px;}
.ModalBox #PastDetailsModal .modal-dialog{ margin: auto; max-width: 500px; } 
.ModalBox #PastDetailsModal .modal-dialog .DoctorConsult .DoctorBody aside{width:100%}
.ModalBox .ModalCenter .modal-dialog{ margin: auto; height: 100%; display: flex; align-items: center;}
.ModalBox .modal-content{ border: none; box-shadow: none; border-radius: 20px; }
.ModalBox .FullModal{padding:0!important}
.ModalBox .FullModal .modal-dialog{margin:0;max-width:100%}
.ModalBox .FullModal .modal-dialog .modal-content{border-radius:0;border:none;box-shadow:none}
.ModalBox .FullModal .LoginArea .LoginRight{display:block}
.ModalBox .FullModal .LoginArea .LoginRight.MedicationSelect{display:flex}
.ModalBox .FullModal .LoginArea .LoginRight.MedicationSelect aside form{margin:0}

.ModalBox .CloseModal{position:absolute;top:-13px;right:-13px;width:30px;height:30px;background-color:#fff;border:2px solid gray;text-align:center;color:#000;font-size:16px;border-radius:50%}
.ModalBox #CardModal .modal-dialog{width:450px}
.ModalBox .Category{padding:20px 20px}
.ModalBox .Category h3{text-align:center;font-weight:700;margin:0 0 20px 0;border-bottom:1px solid #ddd;padding:0 0 15px;font-size:20px}
.ModalBox .Category .form-group{margin:0 0 20px;position:relative}
.ModalBox .Category .form-group label{display:block;font-weight:500;color:#000;font-size:15px;font-family:'Roboto'}
.ModalBox .Category .form-group .form-control{box-shadow:none;border:1px solid #c3c3c3;height:40px;font-size:14px;color:#000;line-height:22px}
.ModalBox .Category .form-group textarea.form-control{height:auto;line-height:26px}
.ModalBox .Category .form-group .Icon{position:absolute;top:40px;right:15px;width:20px}
.ModalBox .Category button{width:233px}

.LocationModal{min-height:inherit}
.LocationModal .LoginRight{width:100%;display:block}
.LocationModal .LoginRight .Map{height:300px;overflow:hidden;position:relative}
.LocationModal .LoginRight .Map .Search{position:absolute;bottom:30px;left:0;right:0;margin:auto;width:75%}
.LocationModal .LoginRight .Map .Search span{position:absolute;top:14px;width:15px;left:15px}
.LocationModal .LoginRight .Map .Search input{height:auto;padding:13px 15px 13px 40px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px}
.LocationModal .LoginRight .Form aside{width:100%;padding:0 30px 30px}

.MedicationSelect .Search{position:relative;margin:0 0 20px 0}
.MedicationSelect .Search span{position:absolute;top:14px;width:15px;left:15px}
.MedicationSelect .Search input{height:auto;padding:13px 15px 13px 40px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px;background-color:#F0F0F0}
.MedicationSelect .SelectBox{height:68vh;overflow:auto;margin:0 -30px 15px 0;padding:0 16px 0 0}

.CongratulationBox{text-align:center;padding:40px 40px}
.CongratulationBox figure{width:90px; line-height: 90px; overflow: hidden; margin:0 auto 30px}
.CongratulationBox figure img{ width: 100%; height: 100%; }
.CongratulationBox h3{font-size:20px;color:#000;font-weight:700;margin:0 0 10px}
.CongratulationBox p{font-size:16px;line-height:26px;margin:0 0 30px 0}
.CongratulationBox a{width:70%;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:18px;color:#fff;font-weight:700;padding:12px 0;border-radius:10px;display:inline-block;text-transform:capitalize}

.SynchronizeBox{text-align:center;padding:40px 40px}
.SynchronizeBox figure{ margin:20px auto 20px}
.SynchronizeBox figure img{ margin: 0 5px; }
.SynchronizeBox h3{font-size:20px;color:#000;font-weight:700;margin:0 0 10px}
.SynchronizeBox p{font-size:16px;line-height:26px;margin:0 0 30px 0}
.SynchronizeBox button{width:70%; outline: 0; background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:18px;color:#fff;font-weight:700;padding:12px 0;border-radius:10px;display:inline-block;text-transform:capitalize}
.SynchronizeBox h6{ margin-top: 20px; }
.SynchronizeBox h6 a{width: 70%; display: block; margin: auto; font-size: 19px; font-weight: 700; color: #5cbbbb;}

.DoctorConsult{padding:50px}
.DoctorConsult .DoctorHead{display:flex;margin:0 0 20px 0}
.DoctorConsult .DoctorHead figure{margin:0 15px 0 0;width:90px;height:90px;   box-shadow:0 10px 10px #ddd;border-radius:25px}
.DoctorConsult .DoctorHead figure img{width:100%; height: 100%; border-radius:25px}
.DoctorConsult .DoctorHead figure span{width:20px;height:20px;background-color:#31d014;position:absolute;border-radius:8px;border:3px solid #fff;right:-9px;top:12px}
.DoctorConsult .DoctorHead figcaption{width:calc(100% - 100px);position: relative;}
.DoctorConsult .DoctorHead figcaption h3{font-weight:700;font-size:20px;margin:0 0 5px 0}
.DoctorConsult .DoctorHead figcaption h5{font-size:15px;color:#000;margin:0 0 6px 0}
.DoctorConsult .DoctorHead figcaption p{display:flex;align-items:center}
.DoctorConsult .DoctorHead figcaption p i{color:#FE9870;font-size:20px;margin:0 10px 0 0}
.DoctorConsult .DoctorHead figcaption p strong{color:#000;font-size:20px;margin:0 10px 0 0;}
.DoctorConsult .DoctorHead figcaption p span{font-weight:600;color:#5f5f5f; cursor: pointer;   font-size: 15px;}
.DoctorConsult .DoctorHead figcaption h6{ position: absolute; right: 0px; top: 0; color: #FE9870; cursor: pointer; }
 
.DoctorConsult .DoctorBody{display:flex;margin:0 0 15px 0}
.DoctorConsult .DoctorBody aside{width:50%}
.DoctorConsult .DoctorBody aside h4{font-size:16px;color:#000;font-weight:700;margin:0 0 10px 0}
.DoctorConsult .DoctorBody aside h4 img{width:30px;margin:0 5px 0 0}
.DoctorConsult .DoctorBody aside h4 a{font-size:15px;font-weight:600;color:#5bbabb;word-break: break-word;}
.DoctorConsult .DoctorBody aside h5{font-size:16px;line-height:29px;color:#000}
.DoctorConsult .DoctorBody aside p{font-size:14px;color:#000;font-weight:500}
.DoctorConsult .DoctorBody aside h3{text-align:center;margin:10px 0 0 0}
.DoctorConsult .DoctorBody aside h3 a{color:#1DA1F2;font-size:18px;font-weight:600;     text-decoration: underline;}
.DoctorConsult .DoctorBody aside h3 a:hover{color:#000}

.DoctorConsult .DoctorBody aside h6{text-align:center;margin:15px 0 0 0}
.DoctorConsult .DoctorBody aside h6 a{color:#5bb9ba;font-size:18px;font-weight:600;     text-decoration: underline;}
.DoctorConsult .DoctorBody aside h6 a:hover{color:#000}

.DoctorConsult .DoctorFooter h5{font-size:17px;font-style:italic;margin:0 0 10px 0;line-height:22px;    color: #737373;}
.DoctorConsult .DoctorFooter p{color:#000;font-weight:600;font-size:15px;line-height:26px}
.DoctorConsult .DoctorButton{ margin: 20px 0px 0 0; display: flex; justify-content: center;}
.DoctorConsult .DoctorButton button{width: 350px; background-image: linear-gradient( 45deg ,#12b2b3,#56e0e0); border: none; font-size: 18px; color: #fff; font-weight: 700; padding: 10px 0; border-radius: 7px; transition: 0.9s all ease-in-out; outline: 0; display: block; text-align: center; outline: 0; margin: 0 10px;}
.DoctorConsult .DoctorButton button:last-child{ color: #1DA1F2; background: #f1f1f1;}
.ModalBox .MedicationBox{padding:30px 40px}
.ModalBox .MedicationBox .MedicationSelect .SelectBox{margin:0 0 20px 0;height:60vh}
.ModalBox .MedicationBox button{width:100%}

.side-open{overflow:hidden;position:relative}
.side-open:before{content:'';position:absolute;top:0;left:0;width:100%;height:100%;background-color:#000000a1;z-index:99}
.FilterArea{height:100vh;transform:translate(120%,0);overflow:auto;background-color:#fff;position:fixed;top:0;right:0;height:100%;width:450px;transition:0.5s all ease-in-out;z-index:100;padding:20px}
.FilterArea .FilterHead{display:flex;justify-content:space-between;margin:0 0 35px 0;align-items:center}
.FilterArea .FilterHead #Close{width:35px;height:35px;border:1px solid #ddd;text-align:center;border-radius:8px;color:#000;font-size:25px;font-weight:500;line-height:30px}
.FilterArea .FilterHead h6{font-size:18px;font-weight:700}
.FilterArea .FilterHead h6 a{color:#1DA1F2}
.FilterArea .FilterBox+.FilterBox{margin:40px 0 0 0}
.FilterArea .FilterBox label{color:#000;font-size:16px;font-weight:700;margin:0 0 15px 0;display:block}
.FilterArea .FilterBox ol{display:flex;border-radius:8px;overflow:hidden;background-color:#F0F0F0}
.FilterArea .FilterBox ol li{text-align:center;position:relative;max-width:100%;width:100%}
.FilterArea .FilterBox ol li input{position:absolute;left:0;width:100%;height:100%;opacity:0;cursor:pointer}
.FilterArea .FilterBox ol li span{background-color:#F0F0F0;display:block;color:#9393AA;font-weight:600;padding:12px 0;font-size:14px;border-radius:5px}
.FilterArea .FilterBox ol li input:checked~span{background-color:#12B2B3;color:#fff}
.FilterArea .FilterBox .Search{position:relative;margin:0 0 10px 0}
.FilterArea .FilterBox .Search span.Icon{position:absolute;top:14px;width:15px;left:15px}
.FilterArea .FilterBox .Search input{height:auto;padding:13px 15px 13px 40px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px;background-color:#F0F0F0}
.FilterArea .FilterBox .Search select{ height:auto;padding:13px 15px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px;background-color:#F0F0F0}
.FilterArea .FilterBox .Search select{height:auto;padding:13px 15px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px;background-color:#F0F0F0}
.FilterArea .FilterBox .FilterList span{background-color:#1DA1F2;margin:6px 5px 0 0;padding:5px 10px;display:inline-block;border-radius:5px;color:#fff;font-weight:600}
.FilterArea .FilterBox .FilterList span a{color:#fff;margin:0 0 0 10px;font-weight:700;font-size:14px}
.FilterArea .FilterBox .CheckBox{padding:0 0 0 40px;margin:0 0 20px 0}
.FilterArea .FilterBox .CheckBox .Checkmark{left:0}
.FilterArea .FilterBox .Show{width:400px;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:20px;color:#fff;font-weight:700;padding:13px 0;border-radius:10px;transition:0.5s all ease-in-out;outline:0;display:block;text-align:center}
.FilterArea .FilterBox .Show:hover{background-position:400px 0}

.DoctorProfile figure{float:right;width:165px;margin:0;height:165px;border:5px solid #5bbabb;border-radius:45px;overflow:hidden}
.DoctorProfile figure img{width:100%;height:100%}
.DoctorProfile ul li{display:flex}
.DoctorProfile ul li+li{margin:20px 0 0 0}
.DoctorProfile ul li label{margin:0 15px 0 0;width:170px;font-size:15px;font-weight:600;position:relative}
.DoctorProfile ul li label:after{content:':';position:absolute;right:0}
.DoctorProfile ul li span{font-size:16px;width:calc(100% - 185px)}

.ModalBox .modal-dialog .Category .rating{max-width:initial}
.ModalBox .modal-dialog .Category .rating i{color:#e0ab13;font-size:20px;margin:0 10px 0 0}

.FeedbackBox{padding:20px}
.FeedbackBox h2{text-align:center;font-weight:700;margin:0 0 20px 0;border-bottom:1px solid #ddd;padding:0 0 15px;font-size:20px}
.FeedbackBox ul{height:500px;overflow:auto;margin:0 -20px 0 0}
.FeedbackBox ul li+li{margin:20px 0 0 0}
.FeedbackBox .FeedbackList aside{position:relative;padding:0 0 0 50px;margin:0 0 10px 0}
.FeedbackBox .FeedbackList aside figure{position:absolute;top:0;left:0;width:40px;height:40px;border-radius:50%;text-align:center;font-size:20px;font-weight:600;color:#fff;line-height:40px;margin:0;overflow:hidden}
.FeedbackBox .FeedbackList aside h3{font-size:17px;font-weight:800;margin:0 0 3px 0}
.FeedbackBox .FeedbackList aside h4{font-size:13px}
.FeedbackBox .FeedbackList p{font-size:15px;line-height:26px}

.AvatarBox .AvatarList aside{padding:0 0 0 0;margin:0 0 15px 0}
.AvatarBox .AvatarList aside h5{margin:0 0 10px 0;font-size:17px;font-weight:500;font-family:Roboto}

.CalenderLeft full-calendar .fc-view-harness.fc-view-harness-active{height:310px!important}
.CalenderLeft full-calendar .fc-toolbar-title{font-size:1.75em;margin:0;margin:0 0 15px 0;color:#9393AA;font-weight:600;font-size:15px!important}
.CalenderLeft full-calendar .fc-button-primary{background-color:transparent!important;border:none;color:#8c8c8c!important;box-shadow:none!important;text-transform:capitalize}
.CalenderLeft .fc-theme-standard .fc-scrollgrid{border:none!important}
.CalenderLeft .fc .fc-col-header-cell-cushion{color:#bbb!important;font-weight:500;font-size:14px}
.CalenderLeft .fc-theme-standard td,
.CalenderLeft .fc-theme-standard th{border:none!important}
.CalenderLeft .fc-theme-standard td{padding:0 0 0 0;background:transparent!important}
.CalenderLeft .fc-daygrid-day-events{display:none!important}
.CalenderLeft .fc .fc-daygrid-day-top{display:block;flex-direction:row-reverse;text-align:center;font-size:15px;font-weight:500;padding:0;background-color:#fff;color:#E0E0E0;opacity:1!important}
.CalenderLeft .fc .fc-daygrid-day-number{padding:10px 0;display:block;background-color:#fff;color:#969696;cursor:pointer}
.CalenderLeft table.fc-scrollgrid-sync-table tr{margin-bottom:5px}
.CalenderLeft .fc-day-sun .fc-daygrid-day-number{color:#000;background-color:#fff}
.CalenderLeft .fc-day-other .fc-daygrid-day-number{background-color:#fff;color:#969696}

.Insight h5{margin:10px 0 0 0;font-size:18px;font-family:Roboto;font-weight:400}
.InsightRange{display:flex;margin:20px 0 0 0}
.InsightRange .InsightRangeBix{width:100%;text-align:center}
.InsightRange .InsightRangeBix .Icon{width:45px;display:block;margin:0 auto 5px}
.InsightRange .InsightRangeBix .Count{font-size:20px;font-weight:600}
.ECGReport{width:250px;margin:45px 0 0 0}
.ECGReport img{width:100%}

.Tablescroll{overflow:auto}
.Tablescroll table{min-width:1300px}

.fc .fc-daygrid-day-frame{height:45px!important}

.CalenderLeft .fc-day-other{position:relative}
.CalenderLeft .fc-day-other:before{content:'';position:absolute;top:0;left:0;width:100%;height:100%;background:#fff0;top:0;z-index:9}

.fc .fc-daygrid-day-bg .fc-highlight{z-index:4!important;background-color:#5cbbbc4f!important}

.AccountLocationForm{display:flex;align-items:center;background-color:#fff;padding:20px;border-radius:10px;box-shadow:0 0 10px #ddd}
.AccountLocationForm .AccountLocationLeft{height:400px;overflow:hidden;position:relative;width:50%}
.AccountLocationForm .AccountLocationLeft .Search{position:absolute;bottom:30px;left:0;right:0;margin:auto;width:90%}
.AccountLocationForm .AccountLocationLeft .Search span{position:absolute;top:14px;width:15px;left:15px}
.AccountLocationForm .AccountLocationLeft .Search input{height:auto;padding:13px 15px 13px 40px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px}
.AccountLocationForm .AccountLocationRight{width:50%;padding:30px}
.AccountLocationForm .AccountLocationRight .form-group{position:relative}
.AccountLocationForm .AccountLocationRight .form-group .Icon{position:absolute;top:40px;right:15px;width:20px}
.AccountLocationForm .AccountLocationRight .form-group label{display:block;color:#000;font-weight:600;font-size:14px}
.AccountLocationForm .AccountLocationRight .form-group .form-control{height:auto;padding:10px 50px 10px 15px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px}
.AccountLocationForm .AccountLocationRight button{width:100%;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:18px;color:#fff;font-weight:700;padding:10px 0;border-radius:5px;transition:0.5s all ease-in-out;outline:0;display:flex;align-items:center;justify-content:center;background:#367dd6}
.AccountLocationForm .AccountLocationRight img{width:16px;margin:0 0 0 7px}
.AccountLocationForm .AccountLocationRight button:hover{background-position:0 90px}

ngx-intl-tel-input{display:block}
.iti.iti--allow-dropdown{display:block}
.iti__flag-box,.iti__flag,
.iti__arrow{display:none!important}
.iti__flag-container{position:absolute;height:40px;bottom:0;left:0;width:80px;line-height:40px}
.selected-dial-code{margin:0 0 0 10px!important;font-size:16px;font-family:Roboto;color:#495057;cursor:pointer}

.dropdown-menu.country-dropdown{margin:0!important;min-width:440px!important;left:0!important;height:250px;overflow:auto}
.dropdown-menu.country-dropdown .search-container input{outline:0;padding:0 20px!important;font-family:'Roboto'}
.dropdown-menu.country-dropdown ul.iti__country-list li{padding:0 15px;cursor:pointer;font-size:15px}
.iti--allow-dropdown .iti__flag-container .iti__selected-flag.dropdown-toggle{width:80px!important;height:41px!important}
.iti--allow-dropdown .iti__flag-container .iti__selected-flag.dropdown-toggle:before{font-family:'FontAwesome';content:'\f107';position:absolute;right:5px;font-size:17px;height:15px;line-height:15px;top:12px}
#phone{padding-left:85px!important;height:41px}

.CheckBox .Checkmark.active{background-color:#12B2B3;border-color:#12B2B3}
.CheckBox .Checkmark.active:after{content:"";position:absolute;display:block}

.AvatarBox .AvatarList aside label{margin:0 0 0 15px}

.active{opacity:1!important}

.Discomfort{display:flex}.Discomfort+.Discomfort{margin-top:20px}.Discomfort a{border:2px solid #ddd;border-radius:10px;background-color:#f0f0f0;padding:15px 50px;color:#676767;font-weight:600;font-size:15px;display:block;width:100%;text-align:center;cursor:pointer}
.Discomfort .DiscomfortAvatar{border:2px solid #ddd;border-radius:10px;padding:15px;margin:0 0 0 0;display:flex;align-items:center}
.Discomfort .DiscomfortAvatar+.DiscomfortAvatar{margin-left:10px}
.Discomfort .DiscomfortAvatar svg{width:70px}
.Discomfort .DiscomfortAvatar .DiscomfortPart{margin:0 0 0 10px}
.Discomfort .DiscomfortAvatar .DiscomfortPart ul li{display:block;color:#000;font-weight:600;font-size:14px}
.Discomfort .DiscomfortAvatar .active{opacity:1}iframe{width:100%;border:none}agm-map{height:100%}

.RequestsBox ul li{display:flex;align-items:center;justify-content:space-between}
.RequestsBox ul li+li{padding:15px 0 0 0;margin:15px 0 0 0;border-top:1px solid #eaeaea}
.RequestsBox ul li .RequestsLeft{display:flex;align-items:center}
.RequestsBox ul li .RequestsLeft figure{width:55px;height:55px;margin:0 15px 0 0;overflow:hidden;border-radius:50%}
.RequestsBox ul li .RequestsLeft figure img{width:100%;height:100%}
.RequestsBox ul li .RequestsLeft h4{font-size:18px;font-weight:700}
.RequestsBox ul li .RequestsRight a{margin:0 0 0 20px;display:inline-block;width:25px}
.RequestsBox ul li .RequestsRight a.Check{color:#5cbbbc}
.RequestsBox ul li .RequestsRight a.Cancel{color:#ce0808}
.RequestsBox ul li .RequestsRight a.View{color:#1da1f2}

.SessionsCout{text-align:center;padding:20px 20px;border-radius:5px;height:100%}
.SessionsCout h3{margin:0 0 5px 0;color:#fff;font-weight:700;font-size:20px}
.SessionsCout h4{color:#fff;font-weight:800;font-size:35px;margin:0 0 6px 0}
.SessionsCout p{color:#fff;font-weight:600;font-size:13px}.Blue{background-color:#54a6f5}

.SessionsMinCount{padding:25px 15px;border-radius:5px;height:100%}
.SessionsMinCount h4{color:#fff;font-size:14px;font-weight:700}
.SessionsMinCount article{display:flex;align-items:center;justify-content:space-between}
.SessionsMinCount article aside{text-align:center}
.SessionsMinCount article aside h5{color:#fff;font-weight:800;font-size:35px;margin:0 0 0 0}
.SessionsMinCount article aside i{color:#fff;font-size:21px}
.SessionsMinCount article aside h6{color:#fff;margin:3px 0 0}
.SessionsMinCount article aside span{color:#fff;font-size:20px}
.SessionsMinCount p{color:#fff;font-weight:600;font-size:13px}

.SessionTab{margin:30px 0 20px 0}
.SessionTab ul li{display:inline-block;margin:0 20px 0 0}
.SessionTab ul li span{background-color:#fff;padding:7px 25px;display:block;font-size:16px;font-weight:600;border-radius:5px;cursor:pointer}
.SessionTab ul li span.active{background:#65cbcd;color:#fff}
.SessionGraph{background-color:#fff;padding:10px;border-radius:10px}
.ButtonBox{text-align:center;margin:0 0 20px 0}
.ButtonBox button{width:150px;padding:10px 0;margin:0 10px;border-radius:6px;border:none;outline:0;font-size:16px;background-color:#c7c1c1}
.ButtonBox button.active{background-color:#367dd6;color:#fff}
.NoAvailable{background-color:#fff;padding:100px 0;border-radius:10px;text-align:center}
.NoAvailable h4{font-weight:800}

.ModalBox .Category .form-group .RatingBox fieldset.rating{border:none;display:inline-block}
.ModalBox .Category .form-group .RatingBox fieldset>input{display:none}
.ModalBox .Category .form-group .RatingBox fieldset>label:before{margin:5px;font-size:1.25em;font-family:FontAwesome;display:inline-block;content:"\f005"}
.ModalBox .Category .form-group .RatingBox fieldset>.half:before{content:"\f089";position:absolute}
.ModalBox .Category .form-group .RatingBox fieldset>label{color:#ddd;float:right;margin:0;cursor:pointer}
  
.ModalBox .Category .form-group .RatingBox fieldset > input:checked ~ label, /* show gold star when clicked */
.ModalBox .Category .form-group .RatingBox fieldset:not(:checked) > label:hover, /* hover current star */
.ModalBox .Category .form-group .RatingBox fieldset:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */
.ModalBox .Category .form-group .RatingBox fieldset > input:checked + label:hover, /* hover current star when changing rating */
.ModalBox .Category .form-group .RatingBox fieldset > input:checked ~ label:hover,
.ModalBox .Category .form-group .RatingBox fieldset > label:hover ~ input:checked ~ label, /* lighten current selection */
.ModalBox .Category .form-group .RatingBox fieldset > input:checked ~ label:hover ~ label { color: #FFED85;  }
.DashboardCount.pink{background-color: #b7b7b7 !important;}


/* new css code :start */

.headFlex{display:flex;justify-content:space-between;padding-bottom:10px}
.DoctorBox .DoctorHead.DoctorHead2{padding-right:0}
a.selectPro{font-size:12px;width:130px;margin-left:8px;color:#fff;min-width:130px;background:#1cb9ba;font-weight:800;height:42px;text-align:center;display:inline-block;padding:12px 5px;border-radius:8px}
.btn_upload{cursor:pointer;display:inline-block;overflow:hidden;position:relative;color:#fff;border:1px dashed#959ba4;padding:15px 10px;text-align:center;width:120px;height:100px}
.btn_upload p{font-size:12px!important;color:#959BA4!important;font-weight:500!important}
.btn_upload i{color:#959BA4;font-size:34px}.yes{display:flex;align-items:flex-start;position:relative;margin-bottom:20px;max-width:245px}
.yes2 label{display:block;font-size:16px;font-weight:700}
.btn_upload input{cursor:pointer;height:100%;position:absolute;filter:alpha(opacity=1);-moz-opacity:0;opacity:0;left:0;top:0}
.it{width:100px;margin-left:5px}
img.preview1{width:100px}
.btn-rmv1,
.btn-rmv2,
.btn-rmv3,
.btn-rmv4,
.btn-rmv5,
.btn-rmv6{display:none}
.rmv{color:#000;border-radius:30px;display:inline-block;background:#fff;margin:-5px -10px;position:absolute;right:16px;height:30px;border:none;width:30px;top:-6px;border:1px solid #ccc}

.vacinationStatus h2{font-size:25px;color:#000;font-weight:700}
.vacinationStatus p{font-size:20px;color:#1965d8;font-weight:500;padding-top:10px}
.vacinationStatus p.not{color:red}
.vacinationStatus{text-align:center;padding:25px}
.vacinationStatus p span{margin-right:10px}
.closed{position:absolute;right:15px;top:15px;z-index:99}
.okdet button{color:#fff;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);padding:10px 35px;border-radius:6px;font-size:14px;font-weight:700}
.okdet{text-align:right}

/* images uploader code :end */
/* new css code :end */

.SubscriptionFirst{ display: none;  }
.SubscriptionPlan{margin:0 0 30px 0}
.SubscriptionPlan article{background-color:#1DA1F2;padding:30px;border-radius:20px;box-shadow:0 3px 10px #b7b7b7;margin:0 0 20px 0;display:flex}
.SubscriptionPlan article .Radio input:checked~.Radiomark { background-color: #ffffff; border-color: #ffffff; }
.SubscriptionPlan article .Radio .Radiomark:after {  border: solid #52a2f3; border-width: 0 2px 2px 0; }
.SubscriptionPlan article aside{border-right:1px solid #fff;width:250px;margin:0 50px 0 0}
.SubscriptionPlan article aside h6{font-size:30px;color:#fff;font-weight:700;margin:0 0 10px 0}
.SubscriptionPlan article aside h3{font-weight:900;color:#fff;font-size:44px}
.SubscriptionPlan article aside h3 span{font-size:16px;font-weight:600}
.SubscriptionPlan article ul{width:65%}
.SubscriptionPlan article ul li{color:#fff;font-weight:500;font-size:14px;width:40%;display:inline-block;position:relative;padding:0 0 0 25px;margin:0 20px 10px 0;max-width:275px}
.SubscriptionPlan article ul li span{position:absolute;top:0;left:0;filter:brightness(0) invert(1)}

/* .PlanArea{display:flex;justify-content:space-between;flex-wrap:wrap}
.PlanArea .PlanBox{background-color:#fff;padding:20px;border-radius:20px;width:32%;box-shadow:0 0 8px #adaaaa} */

.PlanArea{display:flex; flex-wrap:wrap}
.PlanArea .PlanBox{background-color:#fff;padding:20px;border-radius:20px;width:100%;box-shadow:0 0 8px #adaaaa}


.PlanArea .PlanBox .PlanHead{min-height:80px; width: 70%; margin: auto;}
.PlanArea .PlanBox .PlanHead h4{text-align:center;font-size:15px;font-weight:700;margin:0 0 10px 0}
.PlanArea .PlanBox .PlanHead h3{text-align:center;font-size:25px;font-weight:800;color:#000}
.PlanArea .PlanBox .PlanHead h3 span{font-size:15px;font-weight:600;display:block}
.PlanArea .PlanBox ul{min-height:360px}
.PlanArea .PlanBox ul li{color:#000;font-weight:500;font-size:13px;position:relative;padding:0 0 0 20px;margin:0 0 10px 0;line-height:21px}
.PlanArea .PlanBox ul li ol{margin:5px 0}
.PlanArea .PlanBox ul li:before{content:'';background-image:url(../images/check.svg);position:absolute;top:7px;left:0;filter:brightness(0);width:13px;height:15px;background-repeat:no-repeat}
.PlanArea .PlanBox .Start{width:100%;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;padding:12px 0;color:#fff;font-size:15px;font-weight:600;border-radius:7px ; background: #367dd6;}
.PlanArea .PlanBox p{text-align: center; margin: 0px 0 15px;} 
.PlanArea .PlanBox p a{ display: block; }

.paymentget label{display:block;color:#000;font-weight:600;font-size:14px}
.paymentget input,.paycard{height:auto;padding:10px 50px 10px 15px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;width:100%;font-size:14px}
.paymentget .form-group{display:block;max-width:50%}
.paymentget button.paybtn{width:200px;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:18px;color:#fff;font-weight:700;padding:13px 0;border-radius:5px;transition:0.5s all ease-in-out;outline:0;display:block;text-align:center; background: #367dd6;}

.LoginArea .LoginRight.LoginRight22 aside{width:100%}
.LoginArea .LoginRight.LoginRight22 aside .Welcome{padding:50px;border-radius:0;box-shadow:none;margin:0}
.LoginArea .LoginRight.LoginRight22 .paymentget .form-group{max-width:100%}
.LoginArea .LoginRight.LoginRight22 aside form button{width:55%;margin:10px auto 0}

.AppointmentDeatils .TableBox.DiagnosisTable table tr th:first-child, 
.AppointmentDeatils .TableBox.DiagnosisTable table tr td:first-child{ width: 50%; }

.CommentTable{margin-bottom:20px}
.CommentTable tr th{margin:0;font-weight:600;color:#666;vertical-align:text-bottom}
.CommentTable tr td{margin:0;font-size:15px;line-height:24px;font-family:Roboto;background:#F5F5F5;font-weight:400!important}

.CommonForm h4{font-size:14px;color:#3a3a3a;font-weight:600;line-height:22px}
.CommonForm h4 a{color:#52a2f3;display:block}

.DateFilter{background-color:#fff;border-radius:10px;box-shadow:0 0 10px #ddd;margin:0 0 30px 0;padding:20px;display:flex}
.DateFilter .form-group{margin:0 20px 0 0}
.DateFilter .form-group label{color:#666;font-size:15px;display:block;font-weight:600}
.DateFilter .form-group .form-control{height:auto;padding:10px 17px!important;font-weight:600}
.DateFilter .form-group button{padding:8px 30px;background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;color:#fff;font-size:18px;font-weight:600;border-radius:5px;outline:0}
.DateFilter .form-group button.Reset{margin:0 0 0 15px}

.FellingBox{text-align:center}
.FellingBox p{font-size:16px;font-weight:600;line-height:25px;margin:0 0 15px 0}
.FellingBox .Better{background-image:linear-gradient(45deg,#12b2b3,#56e0e0);border:none;font-size:18px;color:#fff;font-weight:700;padding:10px 0;display:block;text-align:center;border-radius:7px;width:200px;margin:auto}

.FilterArea .FilterBox .Search ng-multiselect-dropdown .dropdown-btn{height:auto;padding:13px 15px 13px 15px;border:1px solid #ddd;border-radius:7px;box-shadow:none;font-weight:600;font-size:14px;background-color:#F0F0F0}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret{padding:0!important;height:100%!important}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before{border-width:5px 5px 0}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .dropdown-btn .selected-item{background-color:#1DA1F2!important;margin:6px 5px 0 0;padding:5px 10px!important;display:inline-block!important;border-radius:5px!important;color:#fff!important;font-weight:600;border:none!important;max-width:inherit!important}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .dropdown-btn .selected-item a{margin:0 0 0 10px;font-weight:700;font-size:14px}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .dropdown-list .filter-textbox input{background-color:transparent;padding:0}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .multiselect-dropdown .dropdown-list ul li{padding:10px 15px}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .multiselect-dropdown .dropdown-list ul li div{font-size:14px;font-weight:600}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .multiselect-dropdown .dropdown-list ul li div:before{border-color:#1DA1F2}
.FilterArea .FilterBox .Search ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked+div:before{background:#1DA1F2}
 
.UploadInsurance{width:200px;position:relative}
.UploadInsurance .Close{width:30px;height:30px;position:absolute;background-color:#fff;text-align:center;border-radius:50%;border:2px solid #ddd;font-size:16px;font-weight:700;right:-15px;top:-15px;cursor:pointer}
.Loader{background-color:#00000052;height:100vh;position:fixed;top:0;left:0;width:100%;z-index:999;display:flex;align-items:center;justify-content:center}
.Loader .loader{border:10px solid #f3f3f3;border-radius:50%;border-top:10px solid #1DA1F2;width:120px;height:120px;-webkit-animation:spin 2s linear infinite;animation:spin 2s linear infinite}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.PrivacyBox h2{font-size:17px;font-weight:700;color:#000;margin:0 0 10px 0}
.PrivacyBox p+h2,
.PrivacyBox ul+h2{margin:20px 0 10px 0}
.PrivacyBox ul{padding:0 0 0 24px;list-style:disc}
.PrivacyBox ul li{margin:0 0 10px 0;font-size:14px;font-weight:500;line-height:27px;list-style:disc}

.Comming{position:absolute;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;justify-content:center}
.Comming p{font-size:70px;font-weight:800;color:#000;opacity:.4;transform:rotate(-35deg);letter-spacing:.5px}

.FamilyList{margin-bottom:30px}
.FamilyList ul{padding:0 0 0 20px;margin-bottom:30px}
.FamilyList ul li{list-style:decimal;font-size:17px;margin:0 0 15px 0;font-weight:600}
.FamilyList ul li .FamilyListBox{display:flex;align-items:center;justify-content:space-between}
.FamilyList ul li .FamilyListBox .FamilyListLeft p{font-size:17px;font-weight:700}
.FamilyList ul li .FamilyListBox .FamilyListRigth{display:flex;align-items:center}
.FamilyList ul li .FamilyListBox .FamilyListRigth a.Edit{position:relative;background-color:transparent;padding:0;width:20px;height:20px;right:auto;display:block;color:#5bbabb}
.FamilyList ul li .FamilyListBox .FamilyListRigth a.Delete{position:relative;background-color:transparent;padding:0;width:20px;height:20px;right:auto;display:block;color:red;margin:0 0 0 10px}
.FamilyList a.AddMember{font-weight:600;color:#5ab8b9;font-size:16px}

.LoginArea .LoginRight aside form .form-group .Liveperson{display: flex;}
.LoginArea .LoginRight aside form .form-group .Liveperson .Radio {margin: 0;padding: 0 0 0 30px;font-size: 17px;font-weight: 600;width: 35%;}
.LoginArea .LoginRight aside form .form-group .Liveperson .Radio .Radiomark{top: 4px;}

.ModalBox #DeleteModal .modal-dialog{max-width:450px;margin:50px auto}
.ModalBox .modal-dialog .CloseModal{position:absolute;top:-13px;right:-13px;width:26px;height:26px;background-color:#fff;border:2px solid rgba(0,0,0,.5);text-align:center;color:#000;font-size:15px;border-radius:50%}
.ModalBox .modal-dialog .Decline{margin:0;font-family:"Roboto"; padding: 15px;}
.ModalBox .modal-dialog .Decline h3{font-weight:600;font-size:22px;margin:0 0 7px;text-align:left}
.ModalBox .modal-dialog .Decline p{font-size:16px;color:#888;text-align:left}
.ModalBox .modal-dialog .Decline h4{margin:10px 0 0 0;text-align:right}
.ModalBox .modal-dialog .Decline h4 a{text-transform:capitalize;margin:0 0 0 30px;font-size:17px;color:#49a0d5;font-weight:400;-webkit-transition:0.5s all ease-in-out;transition:0.5s all ease-in-out}
.ModalBox .modal-dialog .Decline h4 a:hover{color:#000}
.ModalBox .modal-dialog .Decline.Reason p{font-size: 14px;font-weight: 500;line-height: 33px;font-family: 'poppins';}

.Download {float: right;background-image: linear-gradient(45deg,#12b2b3,#56e0e0);padding: 9px 20px;color: #fff;border-radius: 7px;font-weight: 600;background: #367dd6;}
.Download:hover{ color: #fff; }
 
.FamilyTable table tr td:last-child{display: flex;}
.FamilyTable table tr td a.Edit{display: inline-block; line-height: 10px; width: 30px;height: 30px;background-color: #beffff;color: #00b4af;padding: 5px;border-radius: 8px;}
.FamilyTable table tr td a.Delete{display: inline-block; line-height: 10px; width: 30px;height: 30px;background-color: #ffd5dc;color: #ff2958;padding: 5px;border-radius: 8px;margin: 0 0 0 5px;}

.NavHeader .navbar{padding:30px 0px}
.NavHeader .navbar .navbar-brand{width:165px;padding:0;margin:0 40px 0 0}
.NavHeader .navbar #Menu{justify-content:space-between}
.NavHeader .navbar ul.navbar-nav li.nav-item a.nav-link{ padding: 10px 20px; color: #a09c9c; font-size: 16px; font-weight: 600; cursor: pointer;}
.NavHeader .navbar ul.navbar-nav li.nav-item a.Login{background-image:linear-gradient(45deg,#12b2b3,#56e0e0);color:#fff;font-weight:500;padding:10px 20px;border-radius:5px}

.HealthArea{padding:65px 50px}
.HealthArea .HealthLeft h3{font-size:60px;font-weight:700;color:#407ddd;margin:0 0 10px 0}
.HealthArea .HealthLeft p{font-size:16px;font-weight:500;line-height:30px;margin:0 0 10px 0}
.HealthArea .HealthLeft ul{margin:60px 0 0 0}
.HealthArea .HealthLeft ul li{display:inline-block;margin:0 40px 0 0}
.HealthArea .HealthLeft ul li a{display:block;width:170px;box-shadow:0 10px 30px 5px rgb(26 35 126 / 20%);border-radius:8px}
.HealthArea .HealthLeft .HealthIcon{margin:100px 0 0 0;display:flex}
.HealthArea .HealthLeft .HealthIcon .Icon{width:110px;margin:0 30px 0 0}
.HealthArea .HealthRight ng-lottie div{ height: 450px !important; }

.PillarsArea{background-color:#f5f6ff;padding:50px 50px 80px}
.PillarsArea h2{font-size:40px;font-weight:700;color:#407ddd;margin:0 0 40px 0;text-align:center}
.PillarsArea .PillarsBox{border:2px solid #5dbebf;background-color:#fff;padding:20px;border-radius:10px;min-height:500px;position:relative;box-shadow:0 0 25px #ccc5c5;transition:0.5s all ease-in-out}
.PillarsArea .PillarsBox:hover{box-shadow:none;transform:translate(0,25px)}
.PillarsArea .PillarsBox h4{text-align:center;font-size:33px;font-weight:700;margin:0 0 20px 0;color:#000;line-height:50px;padding:0 25px}
.PillarsArea .PillarsBox p{font-size:14px;font-weight:500;line-height:31px;margin:0 0 30px 0}
.PillarsArea .PillarsBox a{font-size:16px;text-transform:uppercase;color:#5dbebf;font-weight:500;letter-spacing:.5px;position:absolute;left:20px;bottom:30px}
.PillarsArea .PillarsBox a i{margin:0 0 0 10px;font-size:20px;font-weight:500;transition:0.5s all ease-in-out}
.PillarsArea .PillarsBox a:hover{color:#407ddd}
.PillarsArea .PillarsBox a:hover i{margin:0 0 0 5px}

.RebornArea{padding:60px 50px}
.RebornArea .RebornLeft ng-lottie div{ height: 450px !important; }
.RebornArea .RebornRight h2{font-size:40px;font-weight:700;margin:0 0 40px 0;color:#000;line-height:55px}
.RebornArea .RebornRight ul{margin:0 0 30px 0}
.RebornArea .RebornRight ul li{padding:0 0 0 65px;position:relative;margin:0 0 40px 0}
.RebornArea .RebornRight ul li:last-child{margin:0}
.RebornArea .RebornRight ul li span.Count{color:#fff;width:40px;height:40px;border-radius:50%;background-color:#407ddd;display:inline-block;text-align:center;font-size:17px;line-height:40px;position:absolute;top:0;left:0;font-weight:600}
.RebornArea .RebornRight ul li h4{font-size:32px;font-weight:600;margin:0 0 10px 0;color:#000;line-height:40px;font-family:'Roboto'}
.RebornArea .RebornRight ul li p{font-size:18px;font-weight:400;line-height:35px;margin:0 0 10px 0;font-family:Roboto}
.RebornArea .RebornRight .Signup{background-image:linear-gradient(45deg,#12b2b3,#56e0e0);color:#fff;font-weight:500;padding:13px 30px;border-radius:10px;display:inline-block;font-size:18px;text-transform:capitalize}

.DownloadArea{background-color:#3d5afe;padding:50px 50px}
.DownloadArea h3{color:#fff;font-size:32px;font-weight:600;line-height:60px}
.DownloadArea ul{text-align:right}
.DownloadArea ul li{display:inline-block;margin:0 0 0 20px}
.DownloadArea ul li a{display:block;width:180px}

.Footer{padding:50px}
.Footer .FooterLeft{padding:5px 0}
.Footer .FooterLeft p{font-size:18px;font-weight:600}
.Footer .FooterLeft p a{color:#3d5afe;border-bottom:2px solid #3d5afe;font-style:italic}
.Footer .FooterMiddle ul{display:flex;align-items:center;justify-content:center}
.Footer .FooterMiddle ul li{display:inline-block;margin:0 10px}
.Footer .FooterMiddle ul li a{display:flex;width:40px;height:40px;border:2px solid #1a237e;border-radius:50px;align-items:center;justify-content:center}
.Footer .FooterMiddle ul li a img{width:20px;max-height:20px}
.Footer .FooterRight{padding:5px 0}
.Footer .FooterRight p{font-size:18px;font-weight:600;text-align:right}
.Footer .FooterRight p a{color:#3d5afe;border-bottom:2px solid #3d5afe;font-style:italic}

.ContactArea{padding:50px 0 0; background-color: #efefef; min-height: 100vh;}
.ContactArea h1 { font-size: 30px; font-weight: 800; margin: 0 0 30px 0; }
.ContactArea .ContactBox{text-align:center; background-color: #fff; box-shadow:0 0 10px #c5c5c5;padding:40px 0;min-height:300px;margin:0 0 50px 0}
.ContactArea .ContactBox h3{font-size:22px;font-weight:700;color:#000;margin:0 0 6px 0}
.ContactArea .ContactBox p{font-size:13px;color:#393939;line-height:22px;font-weight:500;margin:0 0 10px 0}
.ContactArea .ContactBox h4{color:#ff924f;font-weight:700;font-size:15px;line-height:23px;margin:0 0 5px 0}
.ContactArea .ContactBox a{font-weight:700;color:#0042b9}

bs-datepicker-container {top: auto !important;bottom: 700px;}
bs-datepicker-container .bs-datepicker-container{ padding: 0; } 
bs-calendar-layout .bs-datepicker-body table.weeks th:first-child,
bs-calendar-layout .bs-datepicker-body table .week{ display: none; }
bs-calendar-layout .bs-datepicker-head,
bs-calendar-layout .bs-datepicker-body table td span.selected{background: linear-gradient(45deg,#12b2b3,#56e0e0);}

.PaymentInputBox{display: flex;}
.PaymentInputBox input#cc-number {padding: 10px;max-width: 700px;}
.PaymentInputBox input#cc-exp-date {margin: 0 0 0 15px;padding: 10px;max-width: 125px;text-align: center;}
.PaymentInputBox input#cc-cvc { margin: 0 0 0 15px;padding: 10px;max-width: 90px;text-align: center;}

.PaymentWelcome{ max-width: 500px;}
.PaymentWelcome .paymentget .form-group{ max-width: 100%; }

.ORBox {margin: 30px 0 -10px 0px;text-align: center;position: relative;}
.ORBox:before {content: '';width: 100%;height: 2px;background-color: #F0F0F0;position: absolute;left: 0;top: 0;bottom: 0;margin: auto}
.ORBox span {width: 40px;height: 40px;background-color: #f0f0f0;display: inline-block;text-align: center;text-transform: uppercase;font-size: 15px;font-weight: 700;border-radius: 50%;line-height: 40px;z-index: 9;position: relative;}

.SubscriptionButton{display: flex;}
.SubscriptionButton .Button {width: 250px;padding: 10px 10px;margin: 0 30px 0 0;}
.SubscriptionButton .Button:hover{background-position: 500px 0;}

.CoachCommonArea{min-height:100vh;background-color:#f7f7f7;padding:20px; margin-top: 66px;}
.CoachHeader {position: fixed;background-color: #fff;box-shadow: 0 0 6px #ddd;display: flex;justify-content: space-between;padding: 10px 25px;align-items: center;z-index: 10;width: 100%;top: 0;}
.CoachHeader figure {margin: 0;width: 145px;}
.CoachHeader .Avater{margin:5px 0 5px 30px;position:relative;display:inline-block}
.CoachHeader .Avater a{display:flex;align-items:center;font-size:14px;color:#000}
.CoachHeader .Avater a figure{width:35px;border-radius:50%;margin:0 15px 0 0;overflow:hidden; height: 35px;}
.CoachHeader .Avater ul{right:0;left:auto;top:100px;border:none;padding:0;margin:0;min-width:200px;opacity:0;visibility:hidden;transition:0.5s all ease-in-out;position:absolute;box-shadow:0 1px 4px #ddd;background-color:#fff}
.CoachHeader .Avater ul li{margin:0;border-bottom:1px solid #d2d3d4}
.CoachHeader .Avater ul li:first-child {background-image: linear-gradient(45deg,#5bbabb,#04bad2);position: relative;padding: 18px 10px 18px 60px;text-align: left;border-bottom: 1px solid #fff;}
.CoachHeader .Avater ul li figure {width: 35px;height: 35px;border-radius: 50%;position: absolute;top: 10px;left: 15px;border: 2px solid #fff;overflow: hidden;}
.CoachHeader .Avater ul li h4 {color: #fff;font-size: 16px;line-height: 18px;}
.CoachHeader .Avater ul li a{color:#4e4e4e;padding:10px 20px;font-weight:400;transition:0.5s all ease-in-out}
.CoachHeader .Avater ul li a span{margin:0 10px 0 0}
.CoachHeader .Avater ul li a:hover{background-color:#58595b;color:#fff}
.CoachHeader .Avater:hover ul{opacity:1;visibility:visible;top:115%}

.PeopleHealthArea {font-family: 'Mulish';min-height: 100vh;background-color: #f7f7f7;padding: 25px 20px;margin-top: 66px;}
.PeopleHealthBox{display:flex;align-items:center}
.PeopleHealthBox+.PeopleHealthBox{margin:25px 0 0 0}
.PeopleHealthBox .PeopleHealthLeft{display:flex;width:215px}
.PeopleHealthBox .PeopleHealthLeft figure{width:60px;height:60px;border-radius:50%;overflow:hidden;margin:0 10px 0 0}
.PeopleHealthBox .PeopleHealthLeft figcaption{width:calc(100% - 70px)}
.PeopleHealthBox .PeopleHealthLeft figcaption h5{font-size:15px;font-weight:700;color:#000}
.PeopleHealthBox .PeopleHealthLeft figcaption h5 a{ color: #000; }
.PeopleHealthBox .PeopleHealthLeft figcaption h6{font-size:13px;font-weight:600;font-family:'Poppins';color:#b3b3b3;margin:6px 0}
.PeopleHealthBox .PeopleHealthLeft figcaption p{text-transform:uppercase;font-weight:600;color:#525252;font-family:'Poppins';font-size:13px}
.PeopleHealthBox .PeopleHealthLeft figcaption a.view{text-transform:uppercase;font-weight:600;color:#000;font-family:'Poppins';font-size:13px}
.PeopleHealthBox .PeopleHealthRight{width:calc(100% - 215px)}
.PeopleHealthBox .PeopleHealthRight ul{display:flex}
.PeopleHealthBox .PeopleHealthRight ul li{flex-grow:1;width:20%;overflow:hidden;max-width:20%;padding:0 5px}
.PeopleHealthBox .PeopleHealthRight ul li .HeartRate h3{text-align:center;font-size:18px;font-weight:700;margin:0 0 6px 0}
.PeopleHealthBox .PeopleHealthRight ul li .HeartRate .HeartRateBox{background-color:#ceedc1;padding:10px;border:2px solid #8cc773}
.PeopleHealthBox .PeopleHealthRight ul li .HeartRate .HeartRateBox h4{text-align:center;font-weight:600;font-family:'Poppins';color:#000;margin:5px 0 10px 0;font-size:20px}
.PeopleHealthBox .PeopleHealthRight ul li .HeartRate .HeartRateBox article{display:flex;justify-content:space-between}
.PeopleHealthBox .PeopleHealthRight ul li .HeartRate .HeartRateBox article aside{text-align:center}
.PeopleHealthBox .PeopleHealthRight ul li .HeartRate .HeartRateBox article aside p{font-size:15px;font-weight:500;font-family:'Poppins';color:#000}
.PeopleHealthBox .PeopleHealthRight ul li .HeartRate .HeartRateBox article aside span{font-size:20px;font-weight:700;color:#000}

.PersonalHealth .PeopleHealthBox{align-items:initial}
.PersonalHealth .PeopleHealthBox .PeopleHealthLeft{padding:25px 0 0 0}
.PersonalHealth .PeopleHealthBox .PeopleHealthRight ul{flex-wrap:wrap}
.PersonalHealth .PeopleHealthBox .PeopleHealthRight ul li{margin:0 0 20px 0}

.CoachMedicalArea{margin:25px -15px 0}
.CoachMedical h3{font-size:20px;margin:0 0 10px 0;font-weight:700;color:#000}
.CoachMedical .card{border:1px solid #ddd}
.CoachMedical .card+.card{margin:15px 0 0 0}
.CoachMedical .card .card-header{border:none;padding:10px 30px 10px 10px;background:transparent;cursor:pointer}
.CoachMedical .card .card-header:before{content:'\f107';font-family:'FontAwesome';border:none;margin-left:0;font-weight:700;font-size:22px;line-height:10px;position:absolute;top:15px;right:20px;transition:0.5s all ease-in-out}
.CoachMedical .card .card-header[aria-expanded="true"]:before{transform:rotate(180deg)}
.CoachMedical .card .card-header h6{font-size:13px;font-weight:700;color:#767676;margin:0 0 2px 0}
.CoachMedical .card .card-header h5{font-size:16px;font-weight:700;color:#000;margin:0 0 2px 0}
.CoachMedical .card .card-header p{color:#8b8a8a;font-weight:600}
.CoachMedical .card .card-body{padding:10px}

.CoachAvatar{background-color:#f7f7f7;padding:15px;border:1px solid #ddd;text-align:center}
.CoachAvatar svg{max-height:600px}
.CoachMedicine ul li{background-color:#f7f7f7;padding:10px;border:1px solid #ddd;border-radius:5px;display:flex;align-items:center;justify-content:space-between}
.CoachMedicine ul li+li{margin:15px 0 0 0}
.CoachMedicine ul li label{margin:0;font-size:14px;font-weight:700;color:#000;width:35%}
.CoachMedicine ul li strong{width:50px;font-weight:800;color:#000;font-size:15px}
.CoachMedicine ul li span{width:35%;font-weight:600;color:#bfbfbf;font-size:14px}
.CoachMedical h4{font-size:16px;font-weight:600;color:#000}

.TaskBox{background-color:#407ddd;padding:20px;border-radius:0}
.TaskBox+.TaskBox{border-top:1px solid #fff}
.TaskBox h3{color:#fff;font-size:22px;font-weight:700;margin:0 0 15px 0;display:flex;align-items:center;justify-content:space-between}
.TaskBox ul li{display:flex;justify-content:space-between}
.TaskBox ul li+li{margin:15px 0 0 0}
.TaskBox ul li p{color:#fff;font-size:15px;font-weight:600}
.TaskBox ul li span{color:#ffffff8a;font-size:13px;font-weight:500}
.TaskBox ul li .CheckBox{padding:0}
.TaskBox ul li .CheckBox .Checkmark{background:transparent;border:2px solid #ebebeb;width:23px;height:23px}
.TaskBox ul li .CheckBox .Checkmark:after{left:7px;top:3px;width:6px;height:11px;border:solid #fff;border-width:0 2px 2px 0;transform:rotate(45deg)}
.TaskBox button{width:130px;margin:20px auto 0;outline:0;background-color:#407ddd;border:2px solid #fff;color:#fff;padding:9px 0;text-transform:uppercase;font-weight:600;letter-spacing:.5px;border-radius:5px;display:block}
.TaskBox button:hover{background-color:#fff;color:#407ddd}

.HeaderIcon{display:flex;align-items:center}
.welcomeName h2{font-size:18px;padding-left:20px;font-weight:700;color:#fff}
.Discomfort.Discomfort22{display:block}
.Discomfort.Discomfort22 .DiscomfortAvatar+.DiscomfortAvatar{margin-left:0;margin-top:25px}
.Discomfort.Discomfort22 .DiscomfortAvatar{justify-content:center}

.DashboardGraph{margin-bottom:25px}
.DashboardGraph h4{color:#a0a0a0;font-size:20px;font-weight:700;margin-bottom:15px;line-height:30px}
.DashboardGraph aside{padding:0 20px 10px;display: flex;justify-content: start;}
.DashboardGraph aside h5{color:#6c9c88;font-size:17px;font-weight:800;margin:0 0 5px 0}
.DashboardGraph aside p{color:#ADAAB4;font-size:14px; }
.DashboardGraph canvas{background-color:#242d31}

.DashboardGraph article {
    background: #242d31;
}

.DashboardGraph article .GraphLabel{
    padding: 0 0 10px 0px;
    display: flex;
    margin: -10px 0 0 0;
}

.DashboardGraph article .GraphLabel span{
    color: #fff;
    font-size: 8px;
    transform: rotate(-45deg);
    text-align: center;

}

.AnalysisBox h5{color:#a0a0a0;text-align:center;margin:0 0 15px 0;font-weight:700;font-size:14px}
.AnalysisBox select{margin:auto;display:block;border:1px solid #ddd;padding:10px 10px;font-size:15px;border-radius:5px;font-weight:600;color:#000; width: 100%;}
.AnalysisBox ul li{display:flex;margin:25px 0 0 0;align-items:center}
.AnalysisBox ul li label{margin:0;color:#a0a0a0;font-size:14px;font-weight:600;min-width:70px;position:relative}

.AnalysisBox ul li label span{
    width: 45px;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 50%;
}

.AnalysisBox ul li label b{
    color: #fff;
    font-weight: 500;
    padding: 0 0 0 10px;
    text-transform: capitalize;
}

.AnalysisBox ul li label::after{content:':';position:absolute;right:5px;top:15px}
.AnalysisBox ul li .Progress{width:calc(100% - 190px);display:flex;align-items:center;}
.AnalysisBox ul li .Progress .Bar{background-color:#377f8b;display:inline-block;height:30px}
.AnalysisBox ul li .Progress .Text{display:inline-block;color:#a0a0a0;font-size:16px;margin:0 10px 0 10px}

.AnalysisBox ul li strong{
    color: #fff;
    font-size: 18px;
    margin: 0 0 0 10px;
    font-weight: 600;
}



.TableList tr td .Edit{width: 40px;height: 40px;background: #d6e3ff;display: inline-block;line-height: 40px;border-radius: 5px;color: #367dd6;font-size: 17px;margin: 0 3px;}
.TableList tr td .Delete{width: 40px;height: 40px;background: #ffe1e1;display: inline-block;line-height: 40px;border-radius: 5px;color: #d11414;font-size: 17px;margin: 0 3px;}

.CheckinNewArea{position:relative;padding:40px 0 0 0}
.CheckinNewArea .BackButton{font-size:16px;position:absolute;right:0;top:0;color:#fff;font-weight:600;display:inline-flex;align-items:center;cursor:pointer}
.CheckinNewArea .BackButton i{font-size:30px;margin:0 10px 0 0}
.CheckinNewArea .CheckinNewHead{text-align:center;margin:0 0 40px 0}
.CheckinNewArea .CheckinNewHead h3{color:#fff;font-size:20px;margin:0 0 20px 0}
.CheckinNewArea .CheckinNewHead select{border:1px solid #ddd;padding:12px 15px;text-align:left;min-width:200px;border-radius:5px;font-size:13px;font-weight:600}
.CheckinNewArea .CheckinNewBody .CheckinNewSymptoms{background-color:#262626;width:500px;margin:auto;padding:40px;border:1px solid #000}
.CheckinNewArea .CheckinNewBody .CheckinNewSymptoms ul{display:flex;flex-wrap:wrap;justify-content:space-between}
.CheckinNewArea .CheckinNewBody .CheckinNewSymptoms ul li{display:inline-block;position:relative;width:48%;margin:0 0 15px 0}
.CheckinNewArea .CheckinNewBody .CheckinNewSymptoms ul li input{position:absolute;top:0;left:0;width:100%;height:100%;cursor:pointer;opacity:0}
.CheckinNewArea .CheckinNewBody .CheckinNewSymptoms ul li span{background-color:#ddd;padding:20px 40px;color:#000;font-size:13px;text-align:center;border-radius:5px;min-height:100px;font-weight:700;display:inline-flex;align-items:center;justify-content:center;width:100%}
.CheckinNewArea .CheckinNewBody .CheckinNewSymptoms ul li input:checked~span{background:#367dd6;color:#fff}

.CheckinNewSeverity{background-color:#262626;width:800px;margin:auto;padding:40px;border:1px solid #000}
.CheckinNewSeverity h3{color:#fff;margin:0 0 20px 0;font-size:25px;font-weight:700;text-align:center}
.CheckinNewSeverity .CheckinNewSeverityHead{ background-color: #fff; padding: 50px; margin: 0 0 20px 0 ; }
.CheckinNewSeverity aside{ margin: 0 -20px 100px -20px; }
.CheckinNewSeverity aside ul{display:flex}
.CheckinNewSeverity aside ul li{width:60px; position: relative; height:40px;color:#000;font-weight:700;line-height:40px;text-align:center;font-size:16px}
.CheckinNewSeverity aside ul li input{ position: absolute; top: 0;  left: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;}
.CheckinNewSeverity aside ul li span{ display: block; transition: 0.5s all ease-in-out;  height: 40px; display: flex;  border: 2px solid #ffff; width: 40px; margin: auto;}
.CheckinNewSeverity aside ul li input:checked ~ span{transform: scale(1.2); z-index: 5;  position: relative; border-radius: 50%; border-color: #367dd6;}
.CheckinNewSeverity article ul{display:flex}
.CheckinNewSeverity article ul li{width:60px;position:relative;height:20px}
.CheckinNewSeverity article ul li:before{content:'';width:2px;height:50px;position:absolute;background:#000;bottom:0;left:0}
.CheckinNewSeverity article ul li:last-child{width:auto}
.CheckinNewSeverity article ul li:last-child::before{content:'';width:2px;height:50px;position:absolute;background:#000;bottom:0;right:0}
.CheckinNewSeverity article ul li span{position:absolute;top:-60px;font-size:16px;font-weight:700;left:-5px}
.CheckinNewSeverity article ul li:nth-child(1) {background-color:rgb(29 161 242 / 10%)}
.CheckinNewSeverity article ul li:nth-child(2) {background-color:rgb(86 224 224 / 20%)}
.CheckinNewSeverity article ul li:nth-child(3) {background-color:rgb(18 178 179 / 30%)}
.CheckinNewSeverity article ul li:nth-child(4) {background-color:rgb(18 178 179 / 40%)}
.CheckinNewSeverity article ul li:nth-child(5) {background-color:rgb(18 178 179 / 60%)}
.CheckinNewSeverity article ul li:nth-child(6) {background-image:linear-gradient(90deg,#71d1d1,#ee9a96)}
.CheckinNewSeverity article ul li:nth-child(7) {background-color:rgb(221 53 46 / 50%)}
.CheckinNewSeverity article ul li:nth-child(8) {background-color:rgb(221 53 46 / 60%)}
.CheckinNewSeverity article ul li:nth-child(9) {background-color:rgb(221 53 46 / 70%)}
.CheckinNewSeverity article ul li:nth-child(10) {background-color:#DD352E;}
.CheckinNewArea .CheckinNewBody .CheckinNewSeverity button{width:200px;background:#367dd6;border:none;font-size:14px;color:#fff;font-weight:600;text-transform:uppercase;padding:15px 0;border-radius:5px;letter-spacing:1px}

.TosterError{position:fixed;right:20px;top:20px;background-color:#df0a0a;z-index:999;padding:15px 25px;border-radius:5px;box-shadow:0 0 10px #bdbdbd;transition:0.5s all ease-in-out}
.TosterError p{color:#fff;font-size:16px;font-weight:600;display:inline-flex;align-items:center}
.TosterError p span{width:30px;height:30px;background-color:#fff;color:#df0a0a;text-align:center;line-height:30px;border-radius:50px;margin:0 10px 0 0}

.SelectedDevice{text-align:center;margin:10px 0 30px 0}
.SelectedDevice p{color:#fff;font-size:15px;font-weight:600;margin:0 0 10px 0}
.SelectedDevice select{min-width:225px;margin:auto;display:block;border:1px solid #ddd;padding:10px 10px;font-size:13px;border-radius:5px;font-weight:600;color:#000}

.MeasurementOption{position:relative}
.MeasurementOption button{width:100%;font-size:14px;background-color:#fff;text-align:left;padding:10px 20px;border-radius:0;outline:0;border:none;font-weight:700;color:#000}
.MeasurementOption ul{position:absolute;top:40px;background-color:#fff;width:100%;padding:20px}
.MeasurementOption ul li{display:flex;align-items:center;justify-content:space-between}
.MeasurementOption ul li p{font-size:15px;font-weight:700;color:#000}
.MeasurementOption ul li+li{margin-top:15px}
.MeasurementOption ul li .Switch{width:35px;height:20px}
.MeasurementOption ul li .Switch .slider:before{width:12px;height:12px;top:4px;left:5px}
.MeasurementOption ul li .Switch input:checked+.slider:before{transform:translateX(12px)}

.HealthWarning{top:70px}
.HealthWarning p{
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin: 50px 0 0 0;
}
.AnalysisBox{position:sticky;top:70px}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}
input[type=number]{-moz-appearance:textfield}

.MeasurementTable{max-height:525px;overflow:auto}
.MeasurementTable table tr th{padding:10px;color:#a0a0a0}
/* .MeasurementTable table tr td{padding:10px} */
/* .MeasurementTable table tr:nth-child(even){background-color:#000} */
/* .MeasurementTable table tr td{float:left}  */

.btnk {
    width: 100%;
    cursor: pointer;
    background-image: linear-gradient(45deg, #12b2b3, #56e0e0);
    border: none;
    font-size: 10px;
    color: #fff;
    font-weight: 700;
    padding: 10px 0;
    border-radius: 7px;
    transition: 0.9s all ease-in-out;
    text-align: center;
    outline: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #367dd6;
}